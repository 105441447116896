/** @format */

// MatrixChart.js

import { Chart as Chartjs, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import { MatrixController, MatrixElement } from "chartjs-chart-matrix";
import zoomPlugin from "chartjs-plugin-zoom";
import { format, getISOWeek } from "date-fns";
import { valueToColor } from "../../utils/Colors";
Chartjs.register(...registerables, MatrixController, MatrixElement, zoomPlugin);

function getUnits(interval) {
  /**
   * Get the units for the time axis based on the interval.
   * @param {String} interval - The interval of the measurements.
   * @returns The units for the time axis:
   * - unitX: The unit for the x-axis.
   * - unitY: The unit for the y-axis.
   * - parser: The parser for the time of the x-axis.
   * - timeFunction: The time function used to transform the values of the x-axis.
   */

  switch (true) {
    case interval.slice(-1) === "s":
      return ["second", "minute", "s", (date) => date.getSeconds().toString()];
    case interval.slice(-1) === "m":
      return ["minute", "hour", "m", (date) => date.getMinutes().toString()];
    case interval.slice(-1) === "h":
      return ["hour", "day", "H", (date) => date.getHours().toString()];
    case interval.slice(-1) === "d":
      return ["day", "week", "i", (date) => (((date.getDay() + 6) % 7) + 1).toString()];
    case interval.slice(-1) === "w":
      return ["week", "year", "I", (date) => getISOWeek(date).toString()];
    case interval.slice(-3) === "mon":
      return ["month", "year", "M", (date) => date.getMonth().toString()];
    case interval.slice(-1) === "y":
      return ["year", "year", "y", (date) => date.getFullYear().toString()];
    default:
      break;
  }
}

// The format for the time axis.
const displayFormats = {
  second: "ss",
  minute: "mm:ss",
  hour: "HH:mm",
  day: "iiiiii",
  week: "I",
  month: "MM-yyyy",
  year: "yyyy",
};

const MatrixChart = ({ measurements, value, formula, dataParams, interval, graphName }) => {
  /**
   * A calenderplot using the chartjs chart matrix package: https://chartjs-chart-matrix.pages.dev/samples/calendar.html
   * @param {Array} measurements - The measurements to be displayed.
   * @param {String} value - The value to be displayed.
   * @param {String} formula - The formula to be displayed.
   * @param {Object} dataParams - The dataParams containing the components.
   * @param {String} interval - The interval of the measurements.
   * @param {String} graphName - The name of the graph.
   * @returns The calenderplot.
   */

  // If there are no measurements, return a message.
  if (measurements.length < 1) {
    return <div>No data to show</div>;
  }

  if (measurements[0][value] === undefined) {
    return null;
  }

  const componentInfo = formula ? dataParams.components[formula] : {};
  const { color_ranges } = componentInfo;

  // Obtain the correct units and such for the two axes
  const [unitX, unitY, parser, timeFunction] = getUnits(interval);

  const data = {
    labels: [],
    datasets: [],
  };

  measurements.forEach((measurement) => {
    data.datasets.push({
      data: measurement.datetime.map((datetime, j) => {
        const date = new Date(datetime);
        return { x: timeFunction(date), y: datetime, d: datetime, v: measurement[value][j] };
      }),
      backgroundColor({ raw }) {
        const value = raw && raw.v ? raw.v : 0;
        return valueToColor(color_ranges, value, true, true);
      },
      borderColor({ raw }) {
        return "rgba(45, 45, 45, 0.15)";
      },
      borderWidth: 1,
      width: ({ chart }) => {
        const area = chart.chartArea || {};
        return area.width / chart.scales.x.ticks.length - 3;
      },
      height: ({ chart }) => {
        const area = chart.chartArea || {};
        return area.height / chart.scales.y.ticks.length - 3;
      },
    });
  });
  // console.log("data", data.datasets[0].data);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: false,
      title: {
        display: graphName !== "" && graphName,
        text: graphName,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title() {
            return "";
          },
          label(context) {
            const v = context.dataset.data[context.dataIndex];
            const value = v.v ? v.v.toFixed(2) : "";
            return ["d: " + format(v.d, "dd/MM/yyyy HH:mm"), "v: " + value];
          },
        },
      },
      animation: {
        duration: 500, // duration of animation in milliseconds
        easing: "easeInOutQuad", // easing function
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true, // Enable zooming with the mouse wheel
          },
          pinch: {
            enabled: true, // Enable zooming by pinching on touch devices
          },
          mode: "xy", // Zoom on the x-axis
        },
        pan: {
          enabled: true,
          mode: "xy",
        },
      },
    },
    scales: {
      x: {
        type: "time",
        position: "top",
        offset: true,
        time: {
          unit: unitX,
          parser: parser,
          isoWeekday: 1,
          displayFormats,
        },
        reverse: false,
        ticks: {
          source: "data",
          padding: 0,
          maxRotation: 0,
        },
        grid: {
          display: false,
          drawBorder: false,
        },
      },
      y: {
        type: "time",
        left: "left",
        offset: true,
        time: {
          unit: unitY,
          round: unitY,
          isoWeekday: 1,
          displayFormats,
        },
        ticks: {
          maxRotation: 0,
          autoSkip: true,
          padding: 1,
        },
        grid: {
          display: false,
          drawBorder: false,
          tickLength: 0,
        },
        title: {
          display: true,
          font: { size: 15, weigth: "bold" },
          text: ({ chart }) => chart.scales.x._adapter.format(Date.now(), "MMM, yyyy"),
          padding: 0,
        },
      },
    },
    layout: {
      padding: {
        top: 10,
      },
    },
  };

  return (
    <div style={{ position: "relative", height: "65vh", width: "100%" }}>
      <Chart type="matrix" data={data} options={options} />
    </div>
  );
};

export default MatrixChart;
