/** @format */

import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

function InfoTooltip({ content, fontSize }) {
  return (
    <Tooltip title={content}>
      <InfoCircleOutlined style={{fontSize: fontSize ? fontSize : null}} />
    </Tooltip>
  );
}

export default InfoTooltip;
