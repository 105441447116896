/** @format */

import "./CustomHexbinLayer.css";
import React, { useRef, useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import L from "leaflet";
import "@asymmetrik/leaflet-d3";
import { valueToColor } from "../../utils/Colors";
import { calculateMedian } from "../../utils/General";
import MeasurementMarker from "../marker/MeasurementMarker";

export function HexbinLayer({ measurements, theme, formula, aggregate, dataParams, includeMarkers }) {
  /**
   * This component creates a hexbin layer on the map with the measurements.
   * Source: https://github.com/bluehalo/leaflet-d3 and https://codesandbox.io/p/sandbox/leaflet-hexbin-zzy008?file=%2Fsrc%2FApp.js%3A10%2C13
   * @param {Array} measurements - The array of measurements.
   * @param {Boolean} includeMarkers - The boolean to include the markers.
   * @returns The hexbin layer with the measurements.
   */

  const map = useMap();

  // The initial marker is just a placeholder but we need an actual marker to be able to open the popup initially
  const markerRef = useRef(null);
  const [marker, setMarker] = useState(
    <MeasurementMarker
      measurement={measurements[0]}
      value={aggregate}
      theme={theme}
      formula={formula}
      dataParams={dataParams}
      arrayData={false}
      latLng={[51.4381, 5.4752]}
      markerStyle={"empty"}
      myRef={markerRef}
    />
  );

  useEffect(() => {
    // If there is no map or no measurements, return
    if (!map || !measurements) return;

    // Get the color ranges
    const componentInfo = formula ? dataParams.components[formula] : {};
    const { color_ranges } = componentInfo;

    // Create the hexbin layer and set the value to the median of the values and use the valueToColor function to get the color
    const hexLayer = L.hexbinLayer({
      radius: 20,
      radiusRange: [4, 20],
      opacity: 0.6,
      duration: 0,
    })
      // .hoverHandler(
      // Lets note that here i is the event and d the data which is super annoying cause in the other events its the other way around
      //   L.HexbinHoverHandler.tooltip({
      //     tooltipContent: (d) => {
      //       const allValues = d.map((item) => item.o[2][aggregate]);
      //       const median = calculateMedian(allValues);
      //       return median ? `Mediaan ${median.toFixed(3)} (${allValues.length})` : "No data";
      //     },
      //   })
      // )
      .fill(function (d, i) {
        let include = false;
        let isAvailable = false;
        const values = d.map((item) => {
          if (item.o[2].include) {
            include = true;
          }

          if (item.o[2].isAvailable) {
            isAvailable = true;
          }

          return item.o[2][aggregate];
        });
        const median = calculateMedian(values);
        return valueToColor(color_ranges, median, include, isAvailable);
      })
      .addTo(map);

    // Add the click event to the hexbin layer which will set the marker to a new marker with the correct popup and location and open it
    hexLayer.dispatch().on("click", function (d, i) {
      d.stopPropagation(); // Remember this line because it took too much time to figure out that it was closing the popups as a result of the click
      const latLng = map.layerPointToLatLng([i.x, i.y]); // NOTE: the layerPointToLatLng and containerPointToLatLng give different results so be careful
      let measurements = i.reduce((acc, obj) => {
        Object.keys(obj.o[2]).forEach((key) => {
          if (!acc[key]) {
            acc[key] = new Set();
          }
          acc[key].add(obj.o[2][key]);
        });
        return acc;
      }, {});

      measurements = Object.keys(measurements).reduce((acc, key) => {
        acc[key] = Array.from(measurements[key]);
        return acc;
      }, {});

      setMarker(
        <MeasurementMarker
          measurement={measurements}
          value={aggregate}
          theme={theme}
          formula={formula}
          dataParams={dataParams}
          arrayData={true}
          latLng={latLng}
          markerStyle={"empty"}
          myRef={markerRef}
        />
      );
      markerRef.current.openPopup();
    });

    // Add a custom mouseover event to the hexbin layer to show the median value of the measurements as the built in tooltip is a bit buggy
    let activeTooltip = null;
    const showTooltip = (d, i) => {
      const latLng = map.layerPointToLatLng([i.x, i.y]);

      const allValues = i.map((item) => item.o[2][aggregate]);
      const median = calculateMedian(allValues);
      const medianString = median ? `Mediaan ${median.toFixed(3)}` : "No data";

      activeTooltip = L.tooltip().setLatLng(latLng).setContent(`${medianString} (${allValues.length})`).openOn(map);
    };

    const hideTooltip = () => {
      if (activeTooltip) {
        map.closeTooltip(activeTooltip);
        activeTooltip = null;
      }
    };

    hexLayer.dispatch().on("mouseover", showTooltip);
    hexLayer.dispatch().on("mouseout", hideTooltip);
    map.on("zoomend moveend", hideTooltip);
    map.on("layeradd", hideTooltip);
    map.on("layerremove", hideTooltip);

    // Add the measurements to the hexbinlayer
    hexLayer.data(measurements.map((measurement) => [measurement.longitude, measurement.latitude, measurement]));

    // Return a cleanup function to remove the hexlayer if the componen becomes unmounted
    return () => {
      hexLayer.remove();
    };
  }, [map, measurements, formula, dataParams, aggregate, theme]);

  // Return the hexbin layer with the optional markers
  return (
    <>
      {includeMarkers
        ? measurements.map((measurement, index) => {
            return (
              <MeasurementMarker
                key={index}
                measurement={measurement}
                value={aggregate}
                theme={theme}
                formula={formula}
                dataParams={dataParams}
                arrayData={false}
                markerStyle={"minimal"}
              />
            );
          })
        : null}

      {marker}
    </>
  );
}

export default HexbinLayer;
