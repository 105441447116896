/** @format */

import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "antd";
import { getMeasurements } from "../../utils/API";
import DataOptionsMenu from "../settings/DataOptionsMenu";
import CalenderPlot from "../charts/CalenderPlot";
import { DataContext } from "../../utils/Context";
import dayjs from "dayjs";

const AnalysisGraphTimeseries = ({ theme, formula, stations, dataParams }) => {
  /**
   * This component is used to display a timeseries graph for the selected sensors.
   * @param {Array} stations - The array of sensor ids.
   * @param {String} graphType - The type of graph which is either "line" or "bar".
   * @returns The timeseries graph.
   */

  const { refresh } = useContext(DataContext);

  // Get the idMetaLookup, selected options and measurements
  const idMetaLookup = dataParams.stations.idMetaLookup;
  const [dataOptions, setDataOptions] = useState({ ...dataParams.defaults.options, range: [dayjs().add(-14, "d"), dayjs()], interval: "1d" });
  const maxStations = 20;

  const { range, aggregate, interval, interpolate, windowsize } = dataOptions;

  // TODO not sure if we need this
  // useEffect(() => {
  //   setDataOptions({ ...dataParams.defaults.options, range: [dayjs().add(-14, "d"), dayjs()], interval: "1d" });
  // }, [dataParams]);

  // Fetch the measurments for the selected sensors
  let [measurements, setMeasurements] = useState([]);
  useEffect(() => {
    const loadMeasurements = async () => {
      console.log("fetching measurements AnalysisGraphTimeseries");
      let measurements = await getMeasurements({
        theme,
        range,
        formula,
        sensorIdsChecked: stations.slice(0, maxStations),
        aggregates: [aggregate],
        bucket: { interval, interpolate },
        timeperiods: null,
        window: null,
        combine: true,
        array_agg: true,
        meta: false,
        enrich: { idMetaLookup, includeAllIds: false },
      });

      setMeasurements(measurements);
    };

    loadMeasurements();
  }, [theme, stations, dataOptions, idMetaLookup, aggregate, range, formula, interval, interpolate, windowsize, refresh]);
  // console.log("measurements calender", measurements);

  // Return the timeseries graph with the options menu
  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col>
            <DataOptionsMenu
              options={[dataParams.options.optionsInterval, dataParams.options.optionsInterpolate, dataParams.options.optionsAggregate]}
              optionsRange={dataParams.options.optionsRange}
              dataOptions={dataOptions}
              setDataOptions={setDataOptions}
            />
          </Col>
        </Row>
        <Row>
          {stations.length > maxStations ? (
            <Col span={24}>
              <h5>
                Showing first {maxStations} of {stations.length} stations
              </h5>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <Row>
          <Col span={24}>
            <CalenderPlot measurements={measurements} value={aggregate} formula={formula} dataParams={dataParams} range={range} interval={interval} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default AnalysisGraphTimeseries;
