/** @format */

import "./App.css";
import ReactGA from "react-ga4";
import { useEffect } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom"; // , Switch, Link
import MainPage from "./pages/MainPage";
import AdminPage from "./pages/AdminPage";

ReactGA.initialize("G-PTBFP0Y8T8");

function App() {
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <BrowserRouter>
      <div className="App">
        <Routes>
          <Route path="/:theme" element={<MainPage />} exact></Route>
          <Route path="/" element={<Navigate replace to="/lucht" />} />

          <Route path="/admin" element={<AdminPage />} exact></Route>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
