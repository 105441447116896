/** @format */

import { Row, Col, Space, Card, Flex, Radio, Checkbox, Switch } from "antd";
import { MapPin, Hexagon, Play, Square, Settings, Wind } from "react-feather";
import InfoTooltip from "../general/InfoTooltip";

function MapOptionsMenu({ markers, setMarkers, includeMarkers, setIncludeMarkers, setTimeslider, settings, setSettings, windLayer, setWindLayer }) {
  /**
   * This component is a menu with options for the map.
   * @param {String} markers - The type of markers to be displayed.
   * @param {Function} setMarkers - The function to set the type of markers.
   * @param {Boolean} includeMarkers - A boolean to include the markers.
   * @param {Function} setIncludeMarkers - The function to set the include markers boolean.
   * @param {Function} setTimeslider - The function to set the timeslider.
   * @param {Boolean} settings - A boolean to show the settings.
   * @param {Function} setSettings - The function to set the settings.
   * @param {Boolean} windLayer - A boolean to show the wind layer.
   * @param {Function} setWindLayer - The function to set the wind layer.
   * @returns The options menu for the map.
   */

  return (
    <Card
      className="map-options-menu"
      // title="Options"
      style={{
        pointerEvents: "auto",
        borderStyle: "solid",
      }}
      styles={{
        body: {
          padding: 10,
        },
        header: {
          height: 10,
        },
      }}
    >
      <Row>
        <Col span={24}>
          <Row style={{ marginBottom: 10 }}>
            <Space>
              <Radio.Group
                defaultValue="markers"
                buttonStyle="solid"
                onChange={(e) => {
                  setMarkers(e.target.value);
                }}
              >
                <Radio.Button value="markers" style={{ textAlign: "center" }}>
                  <MapPin style={{ marginTop: 3 }} />
                </Radio.Button>
                <Radio.Button value="hexbins">
                  <Hexagon style={{ marginTop: 3 }} />
                </Radio.Button>
              </Radio.Group>

              <InfoTooltip
                content={
                  <div>
                    Schakel tussen markers en hexbinnen op de kaart. Markers tonen metingen per station, terwijl hexbinnen stations samenvoegen en de mediaan
                    van de gecombineerde gegevens nemen. Op deze manier wordt een raster gevormd.
                  </div>
                }
              />
            </Space>
          </Row>

          {markers === "hexbins" ? (
            <Row style={{ marginBottom: 10 }}>
              <Space>
                <Checkbox
                  checked={includeMarkers}
                  onChange={(e) => {
                    setIncludeMarkers(e.target.checked);
                  }}
                >
                  Markers
                </Checkbox>

                <InfoTooltip
                  content={
                    <div>
                      Toon de markers op de kaart die overeenkomen met de stations, zodat u kunt zien welke exacte stations bijdragen aan de waarde van een
                      hexbin.
                    </div>
                  }
                />
              </Space>
            </Row>
          ) : null}

          <Row style={{ marginBottom: 10 }}>
            <Space>
              <Radio.Group
                defaultValue="basic"
                buttonStyle="solid"
                onChange={(e) => {
                  setTimeslider(e.target.value);
                }}
              >
                <Radio.Button style={{ width: "50%" }} value="basic">
                  <Square style={{ marginTop: 3 }} />
                </Radio.Button>
                <Radio.Button style={{ width: "50%" }} value="timeslider">
                  <Play style={{ marginTop: 3 }} />
                </Radio.Button>
              </Radio.Group>

              <InfoTooltip
                content={
                  <div>
                    Schakel enkele datapunten en een tijdsbalk die je automatisch kan laten lopen of zelf bewegen. Deze tijdsbalk laat de verandering over tijd
                    zien.
                  </div>
                }
              />
            </Space>
          </Row>

          <Row align="center" style={{ marginBottom: 10 }}>
            <Flex style={{ width: "100%" }}>
              <Switch
                style={{ width: "50%" }}
                checked={windLayer}
                onChange={() => {
                  setWindLayer(!windLayer);
                }}
              />
              <Wind style={{ width: "50%" }} />
              <InfoTooltip
                content={
                  <div>
                    Laat te huidige windrichting zien op de kaart. Deze is alleen gebaseerd op huidige metingen en kan niet terug in de tijd in tegenstelling
                    tot de andere metingen.
                  </div>
                }
              />
            </Flex>
          </Row>

          <Row align="center" style={{ marginBottom: 0 }}>
            <Flex style={{ width: "100%" }}>
              <Switch
                style={{ width: "50%" }}
                checked={settings}
                onChange={() => {
                  setSettings(!settings);
                }}
              />
              <Settings style={{ width: "50%" }} />
              <InfoTooltip content={<div>Extra instellingen aan of uit zetten.</div>} />
            </Flex>
          </Row>
        </Col>
      </Row>
    </Card>
  );
}

export default MapOptionsMenu;
