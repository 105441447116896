/** @format */

import React, { useState, useRef } from "react";
import { Row, Col, Space, Table, Button, Input, Radio } from "antd";
import { SearchOutlined, FilterOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { parseISO } from "date-fns";
import ExportModal from "../general/ExportModal";

const DataTable = ({ measurements, dataParams }) => {
  // Row selection and actions
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  // Table filterin types
  const [tableFilter, setTableFilter] = useState("search");

  // Search per column
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  // If there are no measurements, return a message.
  if (measurements.length < 1) {
    return <div>No data to show</div>;
  }

  // Get the unique values of the measurements for the filters and set the data
  const uniqueValues = {
    ...Object.keys(measurements[0]).reduce((acc, key) => {
      acc[key] = new Set();
      return acc;
    }, {}),
  };
  const data = measurements.map((measurement, index) => {
    Object.keys(measurement).forEach((key) => {
      uniqueValues[key].add(measurement[key]);
    });

    return { ...measurement, key: index };
  });

  // The columns
  const columns = [
    ...Object.keys(measurements[0]).map((key) => {
      // console.log("key", key, typeof measurements[0][key], !isNaN(new Date(measurements[0][key]).getTime()), !isNaN(parseISO(measurements[0][key]).getTime()));
      let sorter;
      let filters = [];
      let onFilter = null;
      switch (typeof measurements[0][key]) {
        case "string": {
          // Check if it is a dateString
          if (!isNaN(parseISO(measurements[0][key]).getTime())) {
            sorter = (a, b) => new Date(a[key]) - new Date(b[key]);
          } else {
            sorter = (a, b) => a[key].localeCompare(b[key]);
            filters = Array.from(uniqueValues[key]).map((value) => {
              return {
                text: value,
                value: value,
              };
            });
            // Specify the condition of filtering result here is that finding the name started with `value`
            onFilter = (value, record) => record[key].indexOf(value) === 0;
          }
          break;
        }
        case "number": {
          sorter = (a, b) => a[key] - b[key];
          break;
        }

        default:
          sorter = (a, b) => a[key] - b[key];
          break;
      }

      const useFilters = tableFilter === "search" ? getColumnSearchProps(key) : { onFilter };
      return {
        title: key,
        dataIndex: key,
        sorter,
        filters: filters,
        filterMode: "tree",
        filterSearch: true,
        onFilter: onFilter,
        sortDirections: ["descend"],
        ...useFilters,
      };
    }),
  ];

  // Finally we return the page with the data and table
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[20, 5]} style={{ marginBottom: 10 }}>
          <Col>
            <Radio.Group
              value={tableFilter}
              buttonStyle="solid"
              onChange={(e) => {
                setTableFilter(e.target.value);
              }}
            >
              <Radio.Button value="search">
                <SearchOutlined />
              </Radio.Button>
              <Radio.Button value="filter">
                <FilterOutlined />
              </Radio.Button>
            </Radio.Group>
          </Col>
          <Col>
            <ExportModal disabled={false} title={"Export"} measurements={measurements} />
          </Col>

          <Col>
            <ExportModal disabled={!hasSelected} title={"Export Selected"} measurements={selectedRowKeys.map((key) => measurements[key])} />
          </Col>

          <Col>
            <span
              style={{
                marginLeft: 8,
              }}
            >
              {hasSelected ? `Selected ${selectedRowKeys.length} items` : ""}
            </span>
          </Col>
        </Row>

        <Row>
          <Table
            size="small"
            pagination={{ defaultPageSize: 50 }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={data}
            showSorterTooltip={{
              target: "sorter-icon",
            }}
            style={{ width: "100%" }}
          />
        </Row>
      </Col>
    </Row>
  );
};

export default DataTable;

// TODO maybe define some filters for specific columns, e.g. the ranges of values for the values
// const ageFilters = [
//   { text: "20-29", value: "20-29" },
//   { text: "30-39", value: "30-39" },
//   { text: "40-49", value: "40-49" },
// ];

// // Function to apply range filters
// const ageFilter = (value, record) => {
//   const [min, max] = value.split("-").map(Number);
//   return record.age >= min && record.age <= max;
// };
