/** @format */

import { Chart as Chartjs, registerables } from "chart.js";
import { Chart, Line, Bar } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import "chartjs-chart-error-bars";
import zoomPlugin from "chartjs-plugin-zoom";
import color from "color";
Chartjs.register(...registerables, zoomPlugin);

const getOriginal = (value) => {
  return value;
};

const getDayOfWeek = (value) => {
  const days = ["Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrijdag", "Zaterdag", "Zondag"];
  return days[parseInt(value) - 1];
};

const getMonthOfYear = (value) => {
  const months = ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"];
  return months[parseInt(value) - 1];
};

function getTimeperiodCallback(timeperiod) {
  /**
   * This function returns the correct callback function based on the timeperiod.
   * @param {String} timeperiod - The timeperiod for the callback function.
   * @returns The callback function.
   */

  switch (timeperiod) {
    case "isodow":
      return getDayOfWeek;
    case "month":
      return getMonthOfYear;
    default:
      return getOriginal;
  }
}

const GraphTimeseries = ({ measurements, value, formula, dataParams, error, timeperiods, graphType, graphName }) => {
  /**
   * This function returns a graph based on the measurements.
   * @param {Array} measurements - The measurements to be displayed.
   * @param {String} value - The value to be displayed.
   * @param {String} error - The error to be displayed.
   * @param {Array} timeperiods - The timeperiods to be displayed.
   * @param {String} graphType - The type of graph to be displayed.
   * @param {String} graphName - The name of the graph.
   * @returns The graph.
   */

  // If there are no measurements, return a message.
  if (measurements.length < 1) {
    return <div>No data to show</div>;
  }

  if (measurements[0][value] === undefined || measurements[0][error] === undefined || timeperiods.some((time) => measurements[0][time] === undefined)) {
    return null;
  }

  const componentInfo = formula ? dataParams.components[formula] : {};
  const { color_ranges } = componentInfo;

  // Set the data and labels for the graph
  const data = {
    datasets: [],
  };

  const allLabels = new Set([]);

  measurements.forEach((measurement, i) => {
    const currentColor = color(`hsl(${i * 40}, 70%, 50%)`);
    const colorPrimary = currentColor.alpha(0.5).rgb().string();
    const colorSecondary = currentColor.alpha(1).rgb().string();
    const colorTertiary = currentColor.alpha(0.2).rgb().string();

    const dataSettingsValue = {
      tension: 0.4,
      borderColor: colorPrimary,
      backgroundColor: colorSecondary,
      pointBackgroundColor: colorPrimary,
      pointBorderColor: colorPrimary,
      pointHoverBackgroundColor: colorPrimary,
      pointHoverBorderColor: colorPrimary,
      spanGaps: true,
    };

    const errorLineSettings = {
      tension: 0.4,
      borderColor: colorTertiary,
      backgroundColor: colorTertiary,
      pointBackgroundColor: colorTertiary,
      pointBorderColor: colorTertiary,
      pointHoverBackgroundColor: colorTertiary,
      pointHoverBorderColor: colorTertiary,
      // pointRadius: 0,
      spanGaps: true,
      fill: "+1", // This makes the fill go from the line to the next dataset (if it exists)
    };

    const values = [];
    const errorsTop = [];
    const errorsBottom = [];
    if (measurement[value]) {
      for (let j = 0; j < measurement[value].length; j++) {
        const constructedTime = timeperiods.map((timeperiod) => measurement[timeperiod][j]).join(";");
        values.push({ x: constructedTime, y: measurement[value][j] });
        errorsTop.push({ x: constructedTime, y: measurement[value][j] + measurement[error][j] });
        errorsBottom.push({ x: constructedTime, y: measurement[value][j] - measurement[error][j] });
        allLabels.add(constructedTime);
      }
    }

    data.datasets.push({
      label: measurement.sensor_id,
      data: values,
      ...dataSettingsValue,
    });

    data.datasets.push({
      label: error,
      data: errorsTop,
      ...errorLineSettings,
    });

    data.datasets.push({
      data: errorsBottom,
      ...errorLineSettings,
      fill: false,
    });
  });

  // Calculate the maxTicks for the multiple x-axis which is the product of the unique values in the timeperiods and the previous maxTicks
  // TODO make sure that this doesn't depend on the first value
  let maxTicks = timeperiods.map((timeperiod, i) => {
    return Math.floor([...new Set(measurements[0][timeperiod])].length);
  });

  for (let i = 0; i < maxTicks.length; i++) {
    const previousMaxTicks = maxTicks[i - 1] || 1;
    maxTicks[i] = maxTicks[i] * previousMaxTicks;
  }

  // Get the callback functions for the timeperiods
  const timeperiodCallbacks = timeperiods.map((timeperiod) => getTimeperiodCallback(timeperiod));

  // The graph options
  let options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: {
      duration: 500, // duration of animation in milliseconds
      easing: "easeInOutQuad", // easing function
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        maxWidth: 230,
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: graphName !== "" && graphName,
        text: graphName,
      },
      zoom: {
        pan: {
          enabled: true,
          mode: "xy",
          threshold: 5,
        },
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
      },
      stripedBackgroundPlugin: {
        color_ranges,
      },
      tooltip: {
        enabled: true,
        callbacks: {
          title: (tooltipItems) => {
            const splittedLabels = tooltipItems[0].label.split(";");
            const newLabel = timeperiodCallbacks
              .map((timeperiodCallback, i) => {
                return timeperiodCallback(splittedLabels[i]);
              })
              .join(" - ");
            return newLabel;
          },
        },
      },
    },

    scales: {
      ...timeperiodCallbacks.reduce((acc, timeperiodCallback, i) => {
        acc[`x${i === timeperiodCallbacks.length - 1 ? "" : timeperiodCallbacks.length - i}`] = {
          // acc[`x${(timeperiodCallbacks.length - i) + 1}`] = {
          type: "category",
          labels: Array.from(allLabels),
          ticks: {
            autoSkip: true,

            callback: function (value, j, values) {
              const realLabel = this.getLabelForValue(value);
              const newLabel = timeperiodCallback(realLabel.split(";")[i]);
              // const previous = values[j-1] || null;
              // console.log("labels", newLabel, value, j, previous, !previous, values);
              // return j === values.indexOf(value) ? newLabel : '';
              return newLabel;
            },
            // maxTicksLimit: maxTicks[i],
            align: "start",
          },
          // NOTE this would be another function to change the ticks
          // afterBuildTicks: function (scale) {
          //   console.log("afterBuildTicks", scale);
          //   scale.ticks.push({ value: 0, label: "0 kWh" })
          // },
          // grid: {
          //   drawOnChartArea: true,
          //   lineWidth: 1 + (timeperiodCallbacks.length - i),
          //   color: function (context) {
          //     const opacity = (0.4 * (timeperiodCallbacks.length - i)) / timeperiodCallbacks.length;
          //     return `rgba(0, 0, 0, ${opacity})`;
          //   },
          // },
          // gridLines: {
          //   offsetGridLines: true
          // },
          position: "bottom",
        };
        return acc;
      }, {}),
    },
  };

  return (
    <div style={{ position: "relative", height: "65vh", width: "100%" }}>
      <Chart type={graphType} data={data} options={options} />
    </div>
  );
};
export default GraphTimeseries;
