/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal, Select } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver"; // Import file-saver for file download

const downloadJson = (measurements) => {
  const jsonContent = JSON.stringify(measurements, null, 2);
  const blob = new Blob([jsonContent], { type: "application/json" });
  saveAs(blob, "data.json");
};

const downloadCsv = (measurements) => {
  const csvContent = convertToCsv(measurements);
  const blob = new Blob([csvContent], { type: "text/csv" });
  saveAs(blob, "data.csv");
};

const convertToCsv = (data) => {
  const headers = Object.keys(data[0]);
  const rows = data.map((obj) => headers.map((header) => obj[header]));
  const csvArray = [headers.join(","), ...rows.map((row) => row.join(","))];
  return csvArray.join("\n");
};

const calculateFileSizeMB = (data, format) => {
  let content;
  if (format === "json") {
    content = JSON.stringify(data, null, 2);
  } else if (format === "csv") {
    content = convertToCsv(data);
  }
  const fileSizeBytes = new Blob([content]).size;
  const fileSizeMB = fileSizeBytes / (1024 * 1024); // Convert bytes to MB
  return fileSizeMB.toFixed(2); // Limit to 2 decimal places
};

const ExportModal = ({ disabled, title, measurements }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const [downloadFormat, setDownloadFormat] = useState("json");
  const [fileSizeMB, setFileSizeMB] = useState(calculateFileSizeMB(measurements, "json"));

  const handleFormatChange = (value) => {
    setDownloadFormat(value);
  };

  useEffect(() => {
    setFileSizeMB(calculateFileSizeMB(measurements, downloadFormat));
  }, [measurements, downloadFormat]);

  const downloadData = () => {
    if (downloadFormat === "json") {
      downloadJson(measurements);
    } else if (downloadFormat === "csv") {
      downloadCsv(measurements);
    }
  };

  return (
    <>
      <Button disabled={disabled} type="primary" onClick={showModal} style={{ marginLeft: 8 }}>
        {title}
      </Button>
      <Modal
        title={title}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="download" onClick={downloadData} type="primary" icon={<DownloadOutlined />}>
            Download
          </Button>,
        ]}
      >
        <p>
          {measurements.length} rows - {fileSizeMB} Mb.
        </p>
        <Select
          options={[
            { label: "JSON", value: "json" },
            { label: "CSV", value: "csv" },
          ]}
          defaultValue={downloadFormat}
          style={{ width: 120, marginLeft: "10px" }}
          onChange={handleFormatChange}
        />
      </Modal>
    </>
  );
};
export default ExportModal;
