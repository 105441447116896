/** @format */

import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import Tour from "./Tour";

const WelcomeModal = () => {
  /**
   * This component shows a welcome modal when the user first visits the website.
   * The modal contains a short explanation of the website and a tour button.
   */

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    const isFirstVisit = localStorage.getItem('firstVisit');
    if (!isFirstVisit) {
      showModal();
      localStorage.setItem('firstVisit', 'true');
    }
  }, []);

  return (
    <>
      {/* <Button type="primary" onClick={showModal}>
        Open Modal
      </Button> */}
      <Modal
        title="Welkom"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Klaar
          </Button>,
          <Tour callbackStart={handleCancel} />,
        ]}
      >
        <p>Dit is het meetnet. Hier kan je de meetingen zien voor fijnstof en geluid, afkomstig van het ILM, RIVM en Eersel F&G.</p>
        <p>LET OP: Vanaf vrijdag 19 juli om 18:15 uur is er een wijziging geweest in de methodiek voor de correcties van geluidsmetingen. Hierdoor is er een wijziging in geluidniveaus zichtbaar.</p>

      </Modal>
    </>
  );
};
export default WelcomeModal;
