/** @format */

import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Popup, LayersControl, Circle, useMap } from "react-leaflet"; // ZoomControl,
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import { Row, Col, Space } from "antd";
import { getMeasurements } from "../../utils/API";
import { usePrevious } from "../../utils/CustomHooks";
import DataOptionsMenu from "../settings/DataOptionsMenu";
import TimeSlider from "../settings/TimeSlider";
import MapOptionsMenu from "../settings/MapOptionsMenu";
import Legend from "./Legend";
import CustomHexbinLayer from "./CustomHexbinLayer";
import CustomMarkerLayer from "./CustomMarkerLayer";
import WindLayer from "./WindLayer";
const { BaseLayer } = LayersControl;

function Map({ theme, formula, stations, dataParams, collapsedWidth, useSmall }) {
  /**
   * This component is the main component for the map page. It fetches the measurements based on the selected options and shows them on the map. It also
   * includes all the other necessary components for the map page.
   * @param {String} theme - The theme of the map page (air, sound).
   * @param {String} formula - The formula of the map page.
   * @param {Array} stations - The stations that are shown on the map.
   * @param {Object} dataParams - The data parameters for the map page.
   * @param {Number} collapsedWidth - The width of the collapsed sidebar.
   * @param {Boolean} useSmall - The state of the screen size.
   * @returns The map with the measurements and the necessary components.
   */

  // The data options for the map
  const [dataOptions, setDataOptions] = useState(dataParams.defaults.options);
  const { range, aggregate, interval, interpolate } = dataOptions;

  // NOTE when the dataParams change the dataoptions have to be reset to their new defaults
  useEffect(() => {
    setDataOptions(dataParams.defaults.options);
  }, [dataParams]);

  // Timeslider settings and other options
  const [timeslider, setTimeslider] = useState("basic");
  const [step, setStep] = useState(0);
  const [settings, setSettings] = useState(false);
  const [markers, setMarkers] = useState("markers");
  const [includeMarkers, setIncludeMarkers] = useState(false);
  const [windLayer, setWindLayer] = useState(false);

  // The fetched measurements also contain the timeslider and timesteps settings so that we always know if the state has been updated
  const [fetchedMeasurements, setFetchedMeasurements] = useState({ measurements: [], timeslider: "basic", timeSteps: [] });

  // Fetching and setting the measurements, the refresh is for updating the measurements manually
  useEffect(() => {
    const loadMeasurements = async () => {
      console.log("fetching measurements Map");

      // Extract the selected options and fetch the measurements, then set the fetched measurements
      let measurements = await getMeasurements({
        theme,
        range,
        formula,
        sensorIdsChecked: stations,
        aggregates: [aggregate],
        bucket: timeslider === "timeslider" ? { interval, interpolate } : null,
        timeperiods: null,
        window: null,
        combine: false,
        array_agg: timeslider === "timeslider" ? true : false,
        meta: false,
        enrich: dataParams ? { idMetaLookup: dataParams.stations.idMetaLookup, includeAllIds: true } : null,
      });

      const timeSteps = measurements.length > 0 && Array.isArray(measurements[0].datetime) ? measurements[0].datetime : [];
      setFetchedMeasurements({ measurements, timeslider, timeSteps });
    };

    loadMeasurements(); // note that just calling loadMeasurements() once does not work as it becomes recursive due to rerendering of the page
  }, [theme, stations, timeslider, dataParams, range, aggregate, formula, interval, interpolate]);
  // console.log("fetchedMeasurements: ", fetchedMeasurements);

  // If a timeslider is used the measurements have to be adjusted to the step
  let measurements = fetchedMeasurements.measurements;
  if (fetchedMeasurements.timeslider === "timeslider") {
    measurements = measurements.reduce((acc, measurement) => {
      if (measurement.datetime && measurement[aggregate]) {
        acc.push({
          ...measurement,
          datetime: measurement.datetime[step],
          [aggregate]: measurement[aggregate][step],
        });
      }

      return acc;
    }, []);
  }

  // The initial position of the map is set to Eindhoven and is updated according to the user settings
  const [position, setPosition] = useState([51.4381, 5.4752]);
  const previousPosition = usePrevious(position);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setPosition([position.coords.latitude, position.coords.longitude]);
          console.log("Succesfully set Geolocation");
        },
        (error) => {
          console.log("Error getting current Geolocation: ", error);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser");
    }
  }, []);

  function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
    return null;
  }

  let showOptions = [];
  switch (timeslider) {
    case "timeslider":
      showOptions = [dataParams.options.optionsInterval, dataParams.options.optionsInterpolate, dataParams.options.optionsAggregate];
      break;
    case "basic":
      showOptions = [dataParams.options.optionsAggregate, dataParams.options.optionsWindowsize];
      break;
    default:
      break;
  }

  // The actual map instance that is to be returned
  return (
    <div style={{ position: "fixed", top: 0, right: 0, bottom: 0, left: collapsedWidth, zIndex: 0 }}>
      <MapContainer center={position} zoom={13} zoomControl={false} style={{ position: "absolute", inset: 0, zIndex: 0 }} preferCanvas={true}>
        {/* This component changes the view of the map as the location is fetched async */}
        {position !== previousPosition ? <ChangeView center={position} zoom={13} /> : null}

        {/* The marker for the user location */}
        <Marker position={position} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })}>
          <Popup>Your location.</Popup>
        </Marker>
        <Circle center={position} pathOptions={{ fillColor: "blue" }} radius={200} />
        <Circle center={position} pathOptions={{ fillColor: "red" }} radius={100} stroke={false} />

        {/* The attribution control */}
        {/* <AttributionControl position="topleft" /> */}

        {/* The built in leaflet layer menu showing different maps */}
        <LayersControl position={useSmall ? "bottomleft" : "topleft"}>
          <BaseLayer checked name="OpenStreetMap">
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="OpenTopoMap">
            <TileLayer
              attribution='Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
              url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri Satelite">
            <TileLayer
              attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>
          <BaseLayer name="Carto">
            <TileLayer
              attribution='&copy; <a href="https://carto.com/">CARTO</a> contributors'
              url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            />
          </BaseLayer>
          <BaseLayer name="Esri">
            <TileLayer
              attribution='&copy; <a href="https://www.esri.com/">ESRI</a>'
              url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            />
          </BaseLayer>

          <LayersControl.Overlay name="Windmap 1">
            <TileLayer
              attribution='Map data &copy; <a href="http://openweathermap.org">OpenWeatherMap</a>'
              url="http://{s}.tile.openweathermap.org/map/wind/{z}/{x}/{y}.png?appid={apiKey}"
              apiKey="495f1b15e99b965fe2b3f18ba29d6e7e"
              opacity={0.5}
            />
          </LayersControl.Overlay>

          <LayersControl.Overlay name="Windmap 2">
            <TileLayer
              attribution='Map data &copy; <a href="http://openweathermap.org">OpenWeatherMap</a>'
              url="http://maps.openweathermap.org/maps/2.0/weather/WND/{z}/{x}/{y}?date=1552861800&use_norm=true&arrow_step=16&appid={apiKey}"
              apiKey="96c44bbb840e48ecf289847370a161a8"
              opacity={0.5}
            />
          </LayersControl.Overlay>
        </LayersControl>

        {/* Showing either markers or hexbins on the map */}
        {markers === "markers" ? (
          <CustomMarkerLayer
            measurements={measurements}
            theme={theme}
            formula={formula}
            aggregate={aggregate}
            dataParams={dataParams}
            includeMarkers={includeMarkers}
          />
        ) : (
          // measurements.map((measurement, index) => {
          //   return <MeasurementMarker key={index} theme={theme} formula={formula} measurement={measurement} value={aggregate} dataParams={dataParams} />;
          // })
          <CustomHexbinLayer
            measurements={measurements}
            theme={theme}
            formula={formula}
            aggregate={aggregate}
            dataParams={dataParams}
            includeMarkers={includeMarkers}
          />
        )}

        {windLayer ? <WindLayer /> : null}
      </MapContainer>

      <Row
        gutter={[20, 5]}
        align="center"
        justify="space-between"
        style={{
          zIndex: 1000, // NOTE I have no idea why this z-index needs to be so high but it won't show otherwise
          position: "fixed",
          bottom: useSmall ? 20 : 25,
          left: useSmall ? 5 : collapsedWidth + 15,
          right: useSmall ? 5 : 15,
          pointerEvents: "none",
        }}
      >
        <Col span={24}>
          <Row gutter={[20, 5]} justify="end" align="bottom">
            {settings || timeslider === "timeslider" ? (
              <Col
                xs={{ order: 1, span: 24 }}
                sm={{ order: 1, span: 24 }}
                md={{ order: 1, span: 24 }}
                lg={{ order: 1, span: 24 }}
                xl={{ order: 0, flex: "auto" }}
              >
                {settings ? (
                  <Row justify="center" style={{ marginBottom: timeslider === "timeslider" ? 5 : 0 }}>
                    <Col>
                      <DataOptionsMenu
                        options={showOptions}
                        optionsRange={dataParams.options.optionsRange}
                        dataOptions={dataOptions}
                        setDataOptions={setDataOptions}
                        setStep={setStep}
                        ignoreInterval={timeslider !== "timeslider"}
                      />
                    </Col>
                  </Row>
                ) : null}

                {timeslider === "timeslider" ? (
                  <Row>
                    <Col span={24}>
                      <TimeSlider timeSteps={fetchedMeasurements.timeSteps} dataOptions={dataOptions} step={step} setStep={setStep} useSmall={useSmall} />
                    </Col>
                  </Row>
                ) : null}
              </Col>
            ) : null}

            <Col>
              <Row justify="end" align="bottom">
                <Space align="end">
                  <MapOptionsMenu
                    markers={markers}
                    setMarkers={setMarkers}
                    includeMarkers={includeMarkers}
                    setIncludeMarkers={setIncludeMarkers}
                    setTimeslider={setTimeslider}
                    settings={settings}
                    setSettings={setSettings}
                    windLayer={windLayer}
                    setWindLayer={setWindLayer}
                  />
                  <Legend componentInfo={dataParams.components[formula]} />
                </Space>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Map;
