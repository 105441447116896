/** @format */

import React from "react";
import { Row, Col, Space, Flex, Button, Layout } from "antd";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import ThemeSelector from "./settings/ThemeSelector";
import FormulaSelector from "./settings/FormulaSelector";
import CustomClock from "./settings/CustomClock";
const { Header } = Layout;

function CustomHeader({ theme, formula, setFormula, refresh, setRefresh, dataParams, collapsed, setCollapsed, width, useSmall }) {
  /**
   * The header component that is displayed at the top of the page.
   * @param {String} theme - The theme of the page.
   * @param {String} formula - The formula of the page.
   * @param {Function} setFormula - The function to set the formula.
   * @param {Boolean} refresh - The refresh state.
   * @param {Function} setRefresh - The function to set the refresh state.
   * @param {Object} dataParams - The data parameters.
   * @param {Boolean} collapsed - The state of the sidebar.
   * @param {Function} setCollapsed - The function to set the state of the sidebar.
   * @param {Number} width - The width of the sidebar.
   * @param {Boolean} useSmall - The state of the screen size.
   * @returns The header component.
   */

  return (
    <Header
      style={{
        padding: 0,
        background: "rgba(0,0,0,0)",
        zIndex: 1,
        pointerEvents: "none",
      }}
    >
      <Row justify="space-between">
        <Col>
          <Row gutter={[20, 5]} align="middle">
            <Col>
              {useSmall ? (
                <Button
                  type="text"
                  icon={collapsed ? <MenuUnfoldOutlined style={{ fontSize: 25 }} /> : <MenuFoldOutlined style={{ fontSize: 25 }} />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    zIndex: 100, // NOTE no idea why this is needed
                    padding: 30,
                    background: "white",
                    pointerEvents: "auto",
                  }}
                />
              ) : null}
            </Col>
          </Row>
        </Col>
      </Row>

      <Flex justify="end" style={{ position: "absolute", top: 10, right: 15 }}>
        <Space>
          <CustomClock initialCountdown={theme === "air" ? 120 : 15} refresh={refresh} setRefresh={setRefresh} />
          <FormulaSelector optionsFormula={dataParams.options.optionsFormula} formula={formula} setFormula={setFormula} />
          <ThemeSelector theme={theme} />
        </Space>
      </Flex>
    </Header>
  );
}

export default CustomHeader;
