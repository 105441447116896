/** @format */

import React, { useState } from "react";
import { createPortal } from "react-dom";
import { Grid, Button, Card } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import AnalysisOverview from "../analysis/AnalysisOverview";
const { useBreakpoint } = Grid;

const MarkerModal = ({ theme, formula, stations, dataParams }) => {
  /**
   * A custom modal which shows tbe analysis overview for the selected marker
   * @param {Array} stations - The stations for which the modal is opened
   * @returns A button which opens the modal and the modal itself
   */

  const screens = useBreakpoint();
  const useSmall = (screens.xs || screens.sm) && !screens.md ? true : false;

  // NOTE that the whole card is null if it is not open as we do not want to (re-)render it
  const [open, setOpen] = useState(false);
  const card = open ? (
    <Card
      title={"Overview"}
      extra={
        <Button
          type="text"
          className="close-button"
          icon={<CloseOutlined />}
          onClick={() => {
            setOpen(false);
          }}
        />
      }
      style={{
        zIndex: 3,
        position: "fixed",
        ...(useSmall ? { inset: 0 } : { left: 90, top: 20, right: 90, bottom: 20 }),
        transition: "all 0.2s",
      }}
    >
      <div style={{ position: "absolute", inset: 0, top: 56, overflow: "auto", padding: 20 }}>
        <AnalysisOverview theme={theme} formula={formula} stations={stations} dataParams={dataParams} />
      </div>
    </Card>
  ) : null;

  return (
    <div>
      <Button type="primary" onClick={() => setOpen(true)}>
        Zie geschiedenis
      </Button>

      <div>{createPortal(card, document.body)}</div>
    </div>
  );
};
export default MarkerModal;

// NOTE the antd Modal is super weird to size so generally use the modal width for the width and then the div inside it for the height in case we want to use it again
// <Modal title="Geschiedenis" centered open={open} onOk={() => setOpen(false)} onCancel={() => setOpen(false)} width={"95vw"}>
//   <div style={{ height: "80vh", width: '100%' }}>
//     <div style={{height: '90%'}}>
//       <AnalysisOverview sensorIds={sensorIds} />
//     </div>
//   </div>
// </Modal>
