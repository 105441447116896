/** @format */

import "./Legend.css";
import InfoTooltip from "../general/InfoTooltip";
import { Flex } from "antd";

function Legend({ componentInfo }) {
  /**
   * This component creates the legend for the map based on the formula.
   * @param {String} formula - The formula of the measurement.
   * @returns The legend for the map.
   */

  // Get the color ranges for the formula
  const { color_ranges, unit } = componentInfo ? componentInfo : {};

  if (!color_ranges) {
    return "#000000";
  }

  return (
    <div className="info legenda leaflet-control" style={{ fontSize: 13 }}>
      <Flex>
        <div style={{ marginRight: 5 }}>{unit}</div>
        <InfoTooltip content={<div>De legenda specifiek voor dit component.</div>} />
      </Flex>
      <br />
      {color_ranges.map((range, index) => {
        return (
          <div key={index} style={{ display: "flex", alignItems: "center" }}>
            <i style={{ background: range.color }}></i>
            <div>
              {range.min} {index === color_ranges.length - 1 ? ">" : ""}
            </div>
            <br />
          </div>
        );
      })}
      <br />
      <i style={{ background: "#000000" }}></i>n/a
      <br />
    </div>
  );
}

export default Legend;
