/** @format */

import React, { useState, useEffect } from "react";
import { Card, Flex } from "antd";
import { RefreshCcw } from "react-feather";
import { useInterval } from "../../utils/General";
import InfoTooltip from "../general/InfoTooltip";

function CustomClock({ initialCountdown, refresh, setRefresh }) {
  /**
   * This component is a custom clock that shows the current time and a countdown timer that can be used to refresh the data.
   * @param {int} initialCountdown - The initial countdown value in seconds
   * @param {boolean} refresh - The refresh state that is used to trigger the refresh
   * @param {function} setRefresh - The function to set the refresh state
   * @returns The custom clock component.
   */

  const [countdown, setCountdown] = useState(initialCountdown);
  const [autoRefresh, setAutoRefresh] = useState(true);

  useEffect(() => {
    setCountdown(initialCountdown);
  }, [initialCountdown]);

  useInterval(() => {
    if (autoRefresh) {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      } else {
        setRefresh(!refresh);
        setCountdown(initialCountdown);
      }
    }
  }, 1000);

  return (
    <Card
      className="custom-clock"
      style={{
        transition: "all 0.2s",
        pointerEvents: "auto",
      }}
      styles={{
        body: {
          padding: 10,
        },
      }}
    >
      <Flex align="center" style={{ fontWeight: "bold" }}>
        <RefreshCcw
          onClick={() => {
            setRefresh(!refresh);
            setCountdown(initialCountdown);
          }}
          style={{ marginRight: 10, cursor: "pointer" }}
        />
        <div
          onClick={() => {
            setAutoRefresh(!autoRefresh);
          }}
          style={{ marginRight: 10, cursor: "pointer" }}
        >
          {countdown}s
        </div>
        <InfoTooltip
          content={<div>Automatisch updaten. Je kan ook pauseren door op de teller te klikken, of nu updaten door op het icoontje te klikken.</div>}
        />
      </Flex>
    </Card>
  );
}

export default CustomClock;
