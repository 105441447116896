/** @format */

import { Space, Radio } from "antd";
import { BarChartOutlined, LineChartOutlined } from "@ant-design/icons";
import InfoTooltip from "../general/InfoTooltip";

function DataGraphtypeMenu({ graphType, setGraphType }) {
  /**
   * Menu to select the graph type which means the way the data is displayed.
   * @param {String} graphType - The current graph type.
   * @param {Function} setGraphType - The function to set the graph type.
   * @returns The graph type menu.
   */

  return (
    <Radio.Group
      value={graphType}
      buttonStyle="solid"
      onChange={(e) => {
        setGraphType(e.target.value);
      }}
    >
      <Radio.Button value="line">
        <Space>
          <LineChartOutlined />
          <InfoTooltip content={<div>Verander naar lijngrafiek.</div>} />
        </Space>
      </Radio.Button>
      <Radio.Button value="bar">
        <Space>
          <BarChartOutlined />
          <InfoTooltip content={<div>Verander naar bargrafiek.</div>} />
        </Space>
      </Radio.Button>
    </Radio.Group>
  );
}

export default DataGraphtypeMenu;
