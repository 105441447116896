/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import { getMeasurements } from "../../utils/API";
import DataOptionsMenu from "../settings/DataOptionsMenu";
import DataviewMenu from "../settings/DataviewMenu";
import DataTable from "../charts/DataTable";

const AnalysisTable = ({ theme, formula, stations, dataParams }) => {
  /**
   * This component is used to display a timeseries graph for the selected sensors.
   * @param {Array} stations - The array of sensor ids.
   * @param {String} graphType - The type of graph which is either "line" or "bar".
   * @returns The timeseries graph.
   */

  // Get the idMetaLookup, selected options and measurements
  const idMetaLookup = dataParams.stations.idMetaLookup;
  const [dataOptions, setDataOptions] = useState(dataParams.defaults.options);
  const [dataView, setDataView] = useState("bucket");
  const [combine, setCombine] = useState(false);
  const maxStations = 20;
  const { range, aggregate, interval, interpolate, windowsize } = dataOptions;

  let showOptions = [];
  switch (dataView) {
    case "bucket":
      showOptions = [dataParams.options.optionsInterval, dataParams.options.optionsInterpolate, dataParams.options.optionsAggregate];
      break;
    case "moving":
      showOptions = [dataParams.options.optionsAggregate, dataParams.options.optionsWindowsize];
      break;
    case "raw":
      showOptions = [];
      break;
    default:
      break;
  }

  // Fetch the measurments for the selected sensors
  let [measurements, setMeasurements] = useState([]);
  useEffect(() => {
    const loadMeasurements = async () => {
      console.log("fetching measurements AnalysisGraphTimeseries");

      let measurements = await getMeasurements({
        theme,
        range,
        formula,
        sensorIdsChecked: stations.slice(0, maxStations),
        aggregates: ["bucket", "moving"].includes(dataView) ? [aggregate] : null,
        bucket: "bucket" === dataView ? { interval, interpolate } : null,
        timeperiods: null,
        window: "moving" === dataView ? { size: windowsize } : null,
        combine,
        array_agg: false,
        meta: false,
        // enrich: { idMetaLookup, includeAllIds: false },
      });

      setMeasurements(measurements);
    };

    loadMeasurements();
  }, [theme, stations, dataOptions, idMetaLookup, dataView, aggregate, range, formula, interval, interpolate, windowsize, combine]);
  // console.log("measurements", measurements);

  // Return the timeseries graph with the options menu
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[20, 5]} align="middle" style={{ marginBottom: 10 }}>
          <Col>
            <DataOptionsMenu
              options={showOptions}
              optionsRange={dataParams.options.optionsRange}
              dataOptions={dataOptions}
              setDataOptions={setDataOptions}
              setStep={null}
              combine={combine}
              setCombine={setCombine}
            />
          </Col>

          <Col>
            <DataviewMenu dataView={dataView} setDataView={setDataView} />
          </Col>
        </Row>

        <Row>
          {stations.length > maxStations ? (
            <Col span={24}>
              <h5>
                Showing first {maxStations} of {stations.length} stations
              </h5>
            </Col>
          ) : (
            ""
          )}
          <Col span={24}>
            <DataTable measurements={measurements} dataParams={dataParams} />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default AnalysisTable;
