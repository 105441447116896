/** @format */

import React, { useState, useEffect } from "react";
import { Grid, Row, Col, Space, Divider } from "antd";
import dayjs from "dayjs";
import { getMeasurements } from "../../utils/API";
import TimeseriesChart from "../charts/TimeseriesChart";
import GaugeChart from "../charts/GaugeChart";
import InfoTooltip from "../general/InfoTooltip";

const { useBreakpoint } = Grid;

function calculateAverage(measurements, value) {
  /**
   * Calculate the average of the measurements
   * @param {Array} measurements - The measurements to calculate the average of
   * @param {String} value - The value to calculate the average of
   * @returns The average of the measurements
   */

  return measurements.map((measurement) => measurement[value]).reduce((a, b) => a + b, 0) / measurements.length;
}

const AnalysisOverview = ({ theme, formula, stations, dataParams }) => {
  /**
   * The AnalysisOverview component displays an overview of the analysis of the measurements.
   * @param {Array} stations - The array of sensor ids.
   * @returns The AnalysisOverview component.
   */

  // Get the screen size which consists of breakpoints set by ant design
  const screens = useBreakpoint();
  const useSmall = (screens.xs || screens.sm) && !screens.md ? true : false;

  // Get the idMetaLookup from the DataContext and the selected options
  const idMetaLookup = dataParams.stations.idMetaLookup;
  const [dataOptions, setDataOptions] = useState(dataParams.defaults.options);
  const maxStations = 20;

  // The measurements and averages correspond to the data that is actually shown
  let [measurements, setMeasurements] = useState([]);
  let [averages, setAverages] = useState({ year: 0, month: 0, week: 0, day: 0 });

  // Fetch the measurements and set the measurments
  useEffect(() => {
    const loadMeasurements = async () => {
      console.log("fetching measurements AnalysisOverview");
      let measurements = await getMeasurements({
        theme,
        range: [dayjs().add(-24, "h"), dayjs()],
        formula,
        sensorIdsChecked: stations.slice(0, maxStations),
        aggregates: ["average"],
        bucket: { interval: "15m", interpolate: true },
        timeperiods: null,
        window: null,
        combine: false,
        array_agg: true,
        meta: false,
        enrich: { idMetaLookup, includeAllIds: false },
      });

      setMeasurements(measurements);
    };

    loadMeasurements();
  }, [theme, idMetaLookup, stations, formula]);
  // console.log("measurements", measurements);

  // Fetch the averages and set the averages
  useEffect(() => {
    const loadAverages = async () => {
      console.log("fetching averages AnalysisOverview");
      const averages = {};
      const averageArray = [
        ["year", -525600],
        ["month", -43200],
        ["day", -1440],
      ];
      await Promise.all(
        averageArray.map(async ([key, minutes]) => {
          let data = await getMeasurements({
            theme,
            range: [dayjs().add(minutes, "m"), dayjs()],
            formula,
            sensorIdsChecked: stations.slice(0, maxStations),
            aggregates: ["average"],
            bucket: null,
            timeperiods: null,
            window: null,
            combine: true,
            array_agg: false,
            meta: false,
            enrich: { idMetaLookup, includeAllIds: false },
          });
          averages[key] = calculateAverage(data, "average");
        })
      );

      setAverages(averages);
    };

    loadAverages();
  }, [theme, idMetaLookup, stations, formula]);
  // console.log("averages", averages);

  // Return the AnalysisOverview component
  return (
    <Row>
      <Col span={useSmall ? 24 : 20} order={useSmall ? 0 : 1}>
        <Row>
          {stations.length > maxStations ? (
            <Col span={24}>
              <h5>
                Showing first {maxStations} of {stations.length} stations
              </h5>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row>
          <Col span={24}>
            <TimeseriesChart
              measurements={measurements}
              value={"average"}
              formula={formula}
              dataParams={dataParams}
              range={[dayjs().add(-24, "h"), dayjs()]}
              graphType={"line"}
              graphName={"De laatste 24 uur in buckets van 15 minuten"}
            />
          </Col>
        </Row>
      </Col>

      <Col span={useSmall ? 24 : 4}>
        <Row>
          <Col span={24}>
            <Space>
              <div>Jaar gemiddelde</div>
              <InfoTooltip content={<div>Het gemiddelde van het afgelopen jaar.</div>} />
            </Space>
            <GaugeChart value={averages.year} formula={dataOptions.formula} dataParams={dataParams} />
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Space>
              <div>Maand gemiddelde</div>
              <InfoTooltip content={<div>Het gemiddelde van de afgelopen maand.</div>} />
            </Space>
            <GaugeChart value={averages.month} formula={dataOptions.formula} dataParams={dataParams} />
            <Divider />
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Space>
              <div>Dag gemiddelde</div>
              <InfoTooltip content={<div>Het gemiddelde van de afgelopen dag.</div>} />
            </Space>
            <GaugeChart value={averages.day} formula={dataOptions.formula} dataParams={dataParams} />
            <Divider />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default AnalysisOverview;
