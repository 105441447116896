/** @format */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Grid, Layout } from "antd";
import { getInitialDataParams } from "../utils/API";
import { DataContext } from "../utils/Context";
import Header from "../components/Header";
import SiderRight from "../components/SiderRight";
import SiderLeft from "../components/SiderLeft";
import WelcomeModal from "../components/guide/WelcomeModel";
import Map from "../components/map/Map";
const { useBreakpoint } = Grid;
const { Content } = Layout;

const MainPage = () => {
  /**
   * This component is the main component for the map page. It fetches the measurements based on the selected options and shows them on the map. It also
   * includes all the other necessary components for the map page.
   */

  // Get the screen size which consists of breakpoints set by ant design
  const screens = useBreakpoint();
  const useSmall = (screens.xs || screens.sm) && !screens.md ? true : false;

  // Get the theme from the url and get the initial dataParams
  let { theme } = useParams();
  switch (theme) {
    case "lucht":
      theme = "air";
      break;
    case "geluid":
      theme = "sound";
      break;
    default:
      theme = "air";
      break;
  }

  // TODO I think that we can combine dataParams and stations into one state as they are always used together
  const [dataParams, setDataParams] = useState(false);
  const [stations, setStations] = useState([]);
  const [formula, setFormula] = useState("");

  // We want to prediodically refresh the data
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    const loadDataParams = async () => {
      const dataParams = await getInitialDataParams(theme);
      setDataParams(dataParams);
      setStations(dataParams.defaults.stations);
      setFormula(dataParams.defaults.options.formula);
    };
    loadDataParams();
  }, [theme]);
  console.log("dataParams: ", dataParams);

  // Sidebar settings are here for now as we want to move some items depending on the collapsed sidebar
  const [collapsedLeft, setCollapsedLeft] = useState(true);
  const widthLeft = useSmall ? 300 : 400;
  const collapsedWidthLeft = useSmall ? 0 : 70;

  // We choose not to render the page if the dataParams are not yet loaded
  if (!dataParams) {
    return <div>Loading...</div>;
  }

  // NOTE we do not really use the datacontext here so it could be removed but it should be used later for language and theme settings
  return (
    <DataContext.Provider value={{ refresh }}>
      <Layout>
        <SiderLeft
          theme={theme}
          formula={formula}
          dataParams={dataParams}
          stations={stations}
          collapsed={collapsedLeft}
          setCollapsed={setCollapsedLeft}
          width={widthLeft}
          collapsedWidth={collapsedWidthLeft}
          useSmall={useSmall}
        />
        <Layout>
          {/* theme, formula, setFormula, refresh, setRefresh, dataParams, collapsed, setCollapsed, width, useSmall */}
          <Header
            theme={theme}
            formula={formula}
            setFormula={setFormula}
            refresh={refresh}
            setRefresh={setRefresh}
            dataParams={dataParams}
            collapsed={collapsedLeft}
            setCollapsed={setCollapsedLeft}
            width={widthLeft}
            useSmall={useSmall}
          />

          <Content style={{ position: "relative", border: "solid" }}>
            {/* The welcome modal which only shows on the first visit */}
            <WelcomeModal />

            {/* The main map showing the data */}
            <Map theme={theme} formula={formula} stations={stations} dataParams={dataParams} collapsedWidth={collapsedWidthLeft} useSmall={useSmall} />
          </Content>
        </Layout>
        <SiderRight dataParams={dataParams} stations={stations} setStations={setStations} useSmall={useSmall} />
      </Layout>
    </DataContext.Provider>
  );
};

export default MainPage;
