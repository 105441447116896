/** @format */

// TODO use binary search to make this more efficient

function between(x, min, max) {
  /**
   * Check if a value is between two values.
   * @param {number} x - The value to check.
   * @param {number} min - The minimum value.
   * @param {number} max - The maximum value.
   * @returns {boolean} - True if the value is between the minimum and maximum value.
   */

  const isBetween = x >= min && x < max;
  return isBetween;
}

function valueToColor(color_ranges, value, include, isAvailable) {
  /**
   * Return the color for a given value and formula based on the color_ranges.
   * @param {Array} color_ranges - The color ranges for the formula.
   * @param {number} value - The value to get the color for.
   * @param {boolean} include - The boolean to include the value.
   * @param {boolean} isAvailable - The boolean to check if the value is available.
   * @returns {string} - The color for the value as a string.
   */

  // If include and isAvailable are defined, return the color based on those values
  if (include !== undefined && isAvailable !== undefined) {
    if (!include) return "#A6A5A5";
    if (include && !isAvailable) return "#FFFFFF";
  }

  // If there is no color_ranges, return black
  if (!color_ranges) {
    return "#000000";
  }

  // If the value is defined and greater than 0, return the color based on the value
  if (value && value > 0) {
    for (const range of color_ranges) {
      if (between(value, range.min, range.max)) {
        return range.color;
      }
    }

    // If the value is not in the color_ranges, return the last color of the ranges
    return color_ranges[color_ranges.length - 1].color;
  }

  // If the value is not defined or 0, return black
  return "#000000";
}

export { valueToColor };
