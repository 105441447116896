/** @format */

import { Chart as Chartjs, registerables } from "chart.js";
import { Chart } from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import zoomPlugin from "chartjs-plugin-zoom";
import color from "color";
import { stripedBackgroundPlugin } from "../../utils/CustomPlugins";
Chartjs.register(...registerables, zoomPlugin, stripedBackgroundPlugin);

function getUnit(range) {
  /**
   * Get the unit for the time axis based on the range.
   * @param {Array} range - The range of the measurements.
   * @returns The unit for the time axis.
   */

  const rangeDiffMinutes = (range[1].$d - range[0].$d) / (1000 * 60);

  let unit = "minute";
  switch (true) {
    case rangeDiffMinutes <= 2:
      unit = "second";
      break;
    case rangeDiffMinutes <= 120:
      unit = "minute";
      break;
    case rangeDiffMinutes <= 60 * 24:
      unit = "hour";
      break;
    case rangeDiffMinutes <= 60 * 24 * 90:
      unit = "day";
      break;
    case rangeDiffMinutes <= 60 * 24 * 365 * 2:
      unit = "month";
      break;
    case rangeDiffMinutes > 60 * 24 * 365 * 2:
      unit = "year";
      break;
    default:
      break;
  }

  return unit;
}

const GraphTimeseries = ({ measurements, value, formula, dataParams, range, graphType, graphName }) => {
  /**
   * This component takes an array of measurements and returns a timeseries graph.
   * @param {Array} measurements - The array of measurements.
   * @param {String} graphType - The type of graph which is either "line" or "bar".
   * @param {String} graphName - The name of the graph.
   * @returns The timeseries graph.
   */

  // If there are no measurements, return a message.
  if (measurements.length < 1) {
    return <div>No data to show</div>;
  }

  if (measurements[0][value] === undefined) {
    return null;
  }

  const componentInfo = formula ? dataParams.components[formula] : {};
  const { color_ranges } = componentInfo;

  // Set the data and labels for the graph
  const data = {
    labels: [],
    datasets: [],
  };

  measurements.forEach((measurement, i) => {
    const colorPrimary = color(`hsl(${i * 40}, 70%, 50%)`)
      .alpha(0.5)
      .rgb()
      .string();
    const colorSecondary = color(`hsl(${i * 40}, 70%, 50%)`)
      .alpha(1)
      .rgb()
      .string();

    data.datasets.push({
      label: measurement.description,
      data: measurement.datetime
        ? measurement.datetime.map((datetime, j) => {
            return { x: datetime, y: measurement[value][j] };
          })
        : [],
      tension: 0.4,
      borderColor: colorPrimary,
      backgroundColor: colorSecondary,
      pointBackgroundColor: colorPrimary,
      pointBorderColor: colorPrimary,
      pointHoverBackgroundColor: colorPrimary,
      pointHoverBorderColor: colorPrimary,
      spanGaps: true,
    });
  });

  // The graph options
  let options = {
    responsive: true,
    maintainAspectRatio: false,
    resizeDelay: 200,
    animation: {
      duration: 500, // duration of animation in milliseconds
      easing: "easeInOutQuad", // easing function
    },
    plugins: {
      legend: {
        position: "bottom",
        align: "start",
        maxWidth: 230,
        labels: {
          usePointStyle: true,
        },
      },
      title: {
        display: graphName !== "" && graphName,
        text: graphName,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true,
          },
          mode: "xy",
        },
        pan: {
          enabled: true,
          mode: "xy",
        },
      },
      stripedBackgroundPlugin: {
        color_ranges,
      },
    },
    tooltips: {
      callbacks: {
        title: function (tooltipItem) {
          return "test";
        },
      },
    },
    scales: {
      x: {
        type: "time",
        title: {
          display: true,
          text: "Date",
        },
        time: {
          unit: getUnit(range),
          displayFormats: {
            second: "HH:mm:ss",
            minute: "HH:mm",
            hour: "dd-MM HH:mm",
            day: "dd-MM",
            month: "MM-yyyy",
            year: "yyyy",
          },
          tooltipFormat: "dd-MM HH:mm",
        },
      },
    },
  };

  return (
    <div style={{ position: "relative", height: "65vh", width: "100%" }}>
      <Chart type={graphType} data={data} options={options} />
    </div>
  );
};
export default GraphTimeseries;
