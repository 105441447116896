/** @format */

import "leaflet-velocity/dist/leaflet-velocity.css";
import "leaflet-velocity/dist/leaflet-velocity.js";
import { useEffect, useState } from "react";
import L from "leaflet";
import { useMap } from "react-leaflet";

const LeafletVelocity = () => {
  const map = useMap();
  const url = "https://maps.sensor.community/data/v1/wind.json";

  const [windData, setWindData] = useState(null);

  useEffect(() =>{
    const loadWindData = async () => {
      const response = await fetch(url)
      const windData = await response.json()
      setWindData(windData)
    }
    loadWindData()

  }, [map])
  // console.log("windData", windData);

  useEffect(() => {
    if (!map || !windData) return;

    const windLayer = L.velocityLayer({
      displayValues: false,
      displayOptions: false,
      data: windData,
      velocityScale: 0.015,
      colorScale: ["#71C3F2", "#447591"],
      minVelocity: 1,
      maxVelocity: 10,
      overlayName: "wind_layer",
    }).addTo(map);

    return () => {
      if (windLayer) {
        windLayer.remove();
      }
    };

  }, [map, windData]);

};

export default LeafletVelocity;

// L.velocityLayer({
//   displayValues: true,
//   displayOptions: {
//     velocityType: "GBR Water",
//     position: "bottomleft",
//     emptyString: "No water data"
//   },
//   data: data,
//   maxVelocity: 0.6,
//   velocityScale: 0.1 // arbitrary default 0.005
// });

// return () => {
//   mounted = false;
//   if (ref.current) {
//     ref.current.removeOverlay(windLayer);
//   }
// };

// const windColorScale = [
//   { velocity: 0, color: "rgba(0, 0, 255, 1)" }, // Blue for calm (0 m/s)
//   { velocity: 5, color: "rgba(0, 128, 255, 1)" }, // Light blue for gentle breeze
//   { velocity: 10, color: "rgba(0, 255, 255, 1)" }, // Cyan for moderate breeze
//   { velocity: 15, color: "rgba(0, 255, 128, 1)" }, // Light green for fresh breeze
//   { velocity: 20, color: "rgba(0, 255, 0, 1)" }, // Green for strong breeze
//   { velocity: 25, color: "rgba(128, 255, 0, 1)" }, // Yellow-green for high wind
//   { velocity: 30, color: "rgba(255, 255, 0, 1)" }, // Yellow for gale
//   { velocity: 35, color: "rgba(255, 128, 0, 1)" }, // Orange for severe gale
//   { velocity: 40, color: "rgba(255, 0, 0, 1)" }, // Red for storm
//   { velocity: 50, color: "rgba(128, 0, 128, 1)" }, // Purple for violent storm
// ];

// function getColorForSpeed(speed) {
//   for (var i = windColorScale.length - 1; i >= 0; i--) {
//     if (speed >= windColorScale[i].velocity) {
//       return windColorScale[i].color;
//     }
//   }
//   return windColorScale[0].color; // Default to the lowest color if speed is below all thresholds
// }
