/** @format */

import { Space, Radio } from "antd";
import InfoTooltip from "../general/InfoTooltip";

function DataviewMenu({ dataView, setDataView }) {
  /**
   * Menu to select the data view which means the way the data is displayed and aggregated.
   * @param {String} dataView - The current data view.
   * @param {Function} setDataView - The function to set the data view.
   * @returns The data view menu.
   */

  return (
    <Radio.Group
      value={dataView}
      buttonStyle="solid"
      onChange={(e) => {
        setDataView(e.target.value);
      }}
    >
      <Radio.Button value="bucket">
        <Space>
          <div>Bucket</div>
          <InfoTooltip content={<div>Aggregeer de data per bucket in de gegeven tijdsperiode.</div>} />
        </Space>
      </Radio.Button>
      <Radio.Button value="moving">
        <Space>
          <div>Raam</div>
          <InfoTooltip content={<div>Neem voor elke datapunt een raam van de gegeven grootte en aggregeer de data.</div>} />
        </Space>
      </Radio.Button>
      <Radio.Button value="raw">
        <Space>
          <div>Rauw</div>
          <InfoTooltip content={<div>Laat de rauw data zien.</div>} />
        </Space>
      </Radio.Button>
    </Radio.Group>
  );
}

export default DataviewMenu;
