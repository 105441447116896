/** @format */

import { useState } from "react";
import { Space, Card, Slider, Button, Flex } from "antd";
import { PlayCircleOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { format, differenceInMinutes } from "date-fns";
import { useInterval } from "../../utils/General";
import InfoTooltip from "../general/InfoTooltip";

function TimeSlider({ timeSteps, dataOptions, step, setStep, useSmall }) {
  /**
   * This component is a slider that changes the step which should correspond to an index of the datetime and value array.
   * @param {Array} timeSteps - The array of datetime values.
   * @param {Object} dataOptions - The options that are currently selected.
   * @param {Number} step - The current step.
   * @param {Function} setStep - The function to set the step.
   * @returns A slider that can be used to change the step.
   */

  const { rangeStart, rangeEnd } = dataOptions;
  const range = differenceInMinutes(rangeEnd, rangeStart);
  let steps = timeSteps.length > 0 ? timeSteps.length - 1 : 0;
  const stepSpeed = 500;

  const [isPlaying, setPlaying] = useState(false);

  useInterval(
    () => {
      if (step < steps) {
        setStep(step + 1);
      }

      if (step === steps) {
        // setPlaying(false);  // in the case the slider should stop when reaching the end
        setStep(0); // in the case the slider should start over when reaching the end
      }
    },
    isPlaying ? stepSpeed : null
  );

  if (timeSteps.length === 0) return null;

  let stepSize = 1;
  const maxStepsMarks = useSmall ? 5 : 12;
  if (steps > maxStepsMarks) {
    stepSize = Math.round(steps / maxStepsMarks);
  }

  const formatString = range > 1440 ? "yyyy-MM-dd" : "HH:mm";
  const marks = {};
  for (let i = 0; i < timeSteps.length; i++) {
    if (i % stepSize === 0 || i === timeSteps.length - 1) {
      // marks[i] = format(addMinutes(timeSteps[i], interval), formatString);
      marks[i] = format(timeSteps[i], formatString);
    }
  }

  const handleSliderChange = (value) => {
    setStep(value);
  };

  return (
    <Card
      style={{ pointerEvents: "auto" }}
      styles={{
        body: {
          padding: 5,
        },
      }}
    >
      <Flex justify="space-between" align="middle" style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Space>
          <Button
            type="primary"
            shape="circle"
            icon={isPlaying ? <PauseCircleOutlined style={{ fontSize: 30 }} /> : <PlayCircleOutlined style={{ fontSize: 30 }} />}
            onClick={() => setPlaying(!isPlaying)}
          ></Button>
        </Space>
        <div style={{ width: "100%", paddingLeft: 20, paddingRight: 20 }}>
          <Slider
            min={0}
            max={steps}
            marks={marks}
            value={step}
            step={1}
            // tooltip={{ formatter: (value) => format(addMinutes(timeSteps[value], interval), formatString) }}
            tooltip={{ formatter: (value) => format(timeSteps[value], formatString) }}
            onChange={handleSliderChange}
          />
        </div>

        <InfoTooltip content={<div>De tijdsbalk van buckets om de progressie van data te laten zien.</div>} />
      </Flex>
    </Card>
  );
}

export default TimeSlider;
