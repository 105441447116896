/** @format */

import { Popover } from "antd";

const LongTextWithPopover = ({ text, maxLength }) => {
  /**
   * This component is a text component that truncates the text and shows a popover with the full text. It is generic and can be used in any component.
   * @param {String} text - The text to be displayed.
   * @param {Number} maxLength - The maximum length of the text before it is truncated.
   * @returns The text component.
   */

  const renderText = () => {
    if (text.length <= maxLength) return text;
    const truncatedText = text.slice(0, maxLength);
    return (
      <Popover content={<div>{text}</div>} trigger="hover">
        {truncatedText}.....
      </Popover>
    );
  };

  return <>{renderText()}</>;
};

export default LongTextWithPopover;
