/** @format */

import { useEffect, useRef } from "react";

function useInterval(callback, delay) {
  /**
   * This function takes a callback function and a delay and returns a setInterval function that will call the callback function at the specified delay.
   * Source: https://www.geeksforgeeks.org/reactjs-useinterval-custom-hook/
   * @param {Function} callback - The callback function.
   * @param {Number} delay - The delay in milliseconds.
   * @returns The setInterval function.
   */

  const savedCallback = useRef();

  // To remember the latest callback .
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Combining the setInterval and clearInterval methods based on delay.
  useEffect(() => {
    function func() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(func, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function createComparator(order) {
  /**
   * This function creates a comparator function that can be used to sort an array based on a specified order.
   * @returns The comparator function.
   */

  return (a, b) => {
    return order.indexOf(a) - order.indexOf(b);
  };
}

const calculateMedian = (arr) => {
  /**
   * This function calculates the median of an array.
   * @param {Array} arr - The array of values.
   * @returns The median of the array.
   */

  const sortedArr = arr.slice().sort((a, b) => a - b);
  const mid = Math.floor(sortedArr.length / 2);
  const median = sortedArr.length % 2 !== 0 ? sortedArr[mid] : (sortedArr[mid - 1] + sortedArr[mid]) / 2;
  return median;
};

export { useInterval, createComparator, calculateMedian };
