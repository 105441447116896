/** @format */

import React from "react";
import { Space, Card, Select } from "antd";
import InfoTooltip from "../general/InfoTooltip";

function FormulaMenu({ optionsFormula, formula, setFormula }) {
  const onChange = (value) => {
    setFormula(value);
  };

  return (
    <Card
      className="formula-selector"
      style={{
        pointerEvents: "auto",
        borderStyle: "solid",
      }}
      styles={{
        body: {
          padding: 5,
          paddingRight: 10,
        },
      }}
    >
      <Space>
        <Select
          showSearch
          placeholder=""
          optionFilterProp=""
          onChange={(value) => onChange(value)}
          filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
          options={optionsFormula.options}
          value={formula}
          variant="borderless"
        />

        <InfoTooltip content={<div>Het component wat je wil laten zien.</div>} />
      </Space>
    </Card>
  );
}

export default FormulaMenu;
