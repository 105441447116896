/** @format */

import React, { useState, useEffect, useContext } from "react";
import { Row, Col } from "antd";

import { getMeasurements } from "../../utils/API";
import DataOptionsMenu from "../settings/DataOptionsMenu";
import DataviewMenu from "../settings/DataviewMenu";
import DataGraphtypeMenu from "../settings/DataGraphtypeMenu";
import TimeseriesChart from "../charts/TimeseriesChart";
import { DataContext } from "../../utils/Context";

const AnalysisGraphTimeseries = ({ theme, formula, stations, dataParams }) => {
  /**
   * This component is used to display a timeseries graph for the selected sensors.
   * @param {Array} stations - The array of sensor ids.
   * @param {String} graphType - The type of graph which is either "line" or "bar".
   * @returns The timeseries graph.
   */

  // The refresh state which is used to externally reload the page
  const { refresh } = useContext(DataContext);

  // Get the idMetaLookup, selected options and measurements
  const idMetaLookup = dataParams.stations.idMetaLookup;
  const [dataOptions, setDataOptions] = useState(dataParams.defaults.options);
  const [dataView, setDataView] = useState(theme === "sound" ? "moving" : "bucket");
  const [combine, setCombine] = useState(false);
  const [graphType, setGraphType] = useState("line");
  const maxStations = 20;

  const { range, aggregate, interval, interpolate, windowsize } = dataOptions;

  let showOptions = [];
  switch (dataView) {
    case "bucket":
      showOptions = [dataParams.options.optionsInterval, dataParams.options.optionsInterpolate, dataParams.options.optionsAggregate];
      break;
    case "moving":
      showOptions = [dataParams.options.optionsAggregate, dataParams.options.optionsWindowsize];
      break;
    case "raw":
      showOptions = [];
      break;
    default:
      break;
  }

  // Fetch the measurments for the selected sensors
  let [measurements, setMeasurements] = useState([]);
  useEffect(() => {
    const loadMeasurements = async () => {
      console.log("fetching measurements AnalysisGraphTimeseries");
      let measurements = await getMeasurements({
        theme,
        range,
        formula,
        sensorIdsChecked: stations.slice(0, maxStations),
        aggregates: ["bucket", "moving"].includes(dataView) ? [aggregate] : null,
        bucket: "bucket" === dataView ? { interval, interpolate } : null,
        timeperiods: null,
        window: "moving" === dataView ? { size: windowsize } : null,
        combine,
        array_agg: true,
        meta: false,
        enrich: { idMetaLookup, includeAllIds: false },
      });

      setMeasurements(measurements);
    };

    loadMeasurements();
  }, [theme, stations, dataOptions, idMetaLookup, dataView, aggregate, range, formula, interval, interpolate, windowsize, combine, refresh]);
  // console.log("measurements timeseriesplot", measurements);

  // Return the timeseries graph with the options menu
  return (
    <Row gutter={[20, 5]}>
      <Col span={24}>
        <Row gutter={[20, 5]} align="middle" style={{ marginBottom: 10 }}>
          <Col>
            <DataOptionsMenu
              options={showOptions}
              optionsRange={dataParams.options.optionsRange}
              dataOptions={dataOptions}
              setDataOptions={setDataOptions}
              setStep={null}
              combine={combine}
              setCombine={setCombine}
            />
          </Col>

          <Col>
            <DataviewMenu dataView={dataView} setDataView={setDataView} />
          </Col>

          <Col>
            <DataGraphtypeMenu graphType={graphType} setGraphType={setGraphType} />
          </Col>
        </Row>

        <Row gutter={[20, 5]}>
          <Col>
            {stations.length > maxStations ? (
              <h5>
                Showing first {maxStations} of {stations.length} stations
              </h5>
            ) : (
              ""
            )}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <TimeseriesChart
              measurements={measurements}
              value={["bucket", "moving"].includes(dataView) ? aggregate : "value"}
              formula={formula}
              dataParams={dataParams}
              range={dataOptions.range}
              graphType={graphType}
              graphName={""}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default AnalysisGraphTimeseries;
