/** @format */

import L from "leaflet";
import { Marker, Popup } from "react-leaflet";
import { List } from "antd";
import { format } from "date-fns";
import MarkerModal from "./MarkerModal";
import { valueToColor } from "../../utils/Colors";
import { calculateMedian } from "../../utils/General";
import LongTextWithPopover from "../general/LongTextWithPopover";

function getDatetime(datetime) {
  return datetime && !Array.isArray(datetime) ? format(datetime, "dd-MM-yyyy HH:mm:ss") : undefined;
}

const MeasurementMarker = ({ measurement, value, theme, formula, dataParams, arrayData = false, latLng = null, markerStyle = "regular", myRef = null }) => {
  /**
   * This component takes a measurement object and returns a marker with a popup containing the measurement data.
   * @param {Object} measurement - The measurement object containing the data of the measurement.
   * @param {Array} latLng - The latitude and longitude of the marker.
   * @param {String} markerStyle - The style of the marker ("regular", "minimal", "empty").
   * @param {Object} myRef - The reference to the marker which will be added to the the actual marker object.
   * @returns The marker with the popup containing the measurement data and the modal.
   */

  let { datetime, network, sensor_id, name, unit, latitude, longitude, description, include, isAvailable } = measurement;
  const componentInfo = formula ? dataParams.components[formula] : {};
  value = measurement[value];
  unit = componentInfo.unit;

  if (!(latitude && longitude && componentInfo)) {
    return;
  }

  const { color_ranges } = componentInfo;
  const color = valueToColor(color_ranges, arrayData ? calculateMedian(value) : value, include, isAvailable);

  // Set the markerstyle
  let measurementIcon;
  switch (markerStyle) {
    case "regular": {
      const markerHtmlStyles = `
        background-color: ${color};
        position: absolute;
        inset: 0;
        border-radius: 50% 50% 0% 50%;
        transform: rotate(45deg);
        border: 1px solid #3C3C3D";
        ${!include || !isAvailable ? "opacity: 0.7" : ""};
      `;

      measurementIcon = L.divIcon({
        className: "my-custom-pin",
        iconSize: [20, 20],
        iconAnchor: [10, 20],
        popupAnchor: [0, -20],
        html: `<span style="${markerHtmlStyles}" />`,
      });
      break;
    }
    case "minimal": {
      const markerHtmlStyles = `
        background-color: #A6A5A5;
        position: absolute;
        inset: 0;
        border-radius: 50% 50% 50% 50%;
        border: 1px solid #3C3C3D;
      `;

      measurementIcon = L.divIcon({
        className: "my-custom-pin",
        iconSize: [6, 6],
        iconAnchor: [3, 3],
        popupAnchor: [0, 0],
        html: `<span style="${markerHtmlStyles}" />`,
      });
      break;
    }

    case "count": {
      const count = arrayData ? value.length : 1;
      const size = 25 + count > 100 ? 100 : 25 + count;
      const markerHtmlStyles = `
        background-color: ${color};
        position: absolute;
        inset: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        font-size: 14px;
        border-radius: 50%;
        opacity: 0.7;
      `;

      measurementIcon = L.divIcon({
        iconSize: [size, size],
        iconAnchor: [size / 2, size / 2],
        popupAnchor: [0, 0],
        html: `<div class="cluster-marker" style="${markerHtmlStyles}">${count}</div>`,
      });
      break;
    }

    case "empty": {
      measurementIcon = L.divIcon({
        className: "my-custom-pin",
      });
      break;
    }
    default:
      return;
  }

  // Format the data for the popup depending on the type of data
  const maxLength = 100;
  let sensor_id_new = "";
  if (arrayData) {
    datetime = <LongTextWithPopover text={datetime.map((datetime) => getDatetime(datetime)).join(", ")} maxLength={maxLength} />;
    network = <LongTextWithPopover text={network.join(", ")} maxLength={maxLength} />;
    description = <LongTextWithPopover text={description.join(", ")} maxLength={maxLength} />;
    sensor_id_new = <LongTextWithPopover text={sensor_id.join(", ")} maxLength={maxLength} />;
    name = <LongTextWithPopover text={name.join(", ")} maxLength={maxLength} />;
    value = <LongTextWithPopover text={value.map((val) => (val ? val.toFixed(3) : "")).join(", ")} maxLength={maxLength} />;
  } else {
    datetime = getDatetime(datetime);
    sensor_id_new = sensor_id;
  }

  // Construct the data array for the list of the popup
  const data = [
    <div>
      <strong>Tijd: </strong> {datetime}
    </div>,
    <div>
      <strong>Bron: </strong> {network}
    </div>,
    <div>
      <strong>Omschrijving: </strong> {description}
    </div>,
    <div>
      <strong>Grootheid: </strong> {formula}
    </div>,
    <div>
      <strong>Eenheid: </strong> {unit}
    </div>,
    <div>
      <strong>Sensor ID: </strong> {sensor_id_new}
    </div>,
    <div>
      <strong>Sensor Naam: </strong> {name}
    </div>,
    <div>
      <strong>Waarde: </strong> {value}
    </div>,
  ];

  // Return the marker with the popup and a modal
  return (
    <Marker ref={myRef} position={latLng ? latLng : [latitude, longitude]} icon={measurementIcon}>
      <Popup>
        <List
          size="small"
          header={<h2 style={{ fontWeight: "bold", margin: 0 }}>Meetpunt specificaties</h2>}
          footer={<MarkerModal theme={theme} formula={formula} stations={Array.isArray(sensor_id) ? sensor_id : [sensor_id]} dataParams={dataParams} />}
          dataSource={data}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      </Popup>
    </Marker>
  );
};

export default MeasurementMarker;
