/** @format */

import "./CustomMarkerLayer.css";
import React, { useEffect, useState } from "react";
import { useMap } from "react-leaflet";
import useSupercluster from "use-supercluster";
import MeasurementMarker from "../marker/MeasurementMarker";

// get map bounds
function updateMap(map, setBounds, setZoom) {
  const b = map.getBounds();
  setBounds([b.getSouthWest().lng, b.getSouthWest().lat, b.getNorthEast().lng, b.getNorthEast().lat]);
  setZoom(map.getZoom());
}

function CustomMarkerLayer({ measurements, theme, formula, aggregate, dataParams }) {
  const [bounds, setBounds] = useState(null);
  const [zoom, setZoom] = useState(12);
  const map = useMap();

  useEffect(() => {
    const onMove = () => {
      updateMap(map, setBounds, setZoom);
    };

    map.on("move", onMove);
    return () => {
      map.off("move", onMove);
    };
  }, [map]);

  const points = measurements.map((measurement) => ({
    type: "Feature",
    properties: { cluster: false, measurement },
    geometry: {
      type: "Point",
      coordinates: [parseFloat(measurement.longitude), parseFloat(measurement.latitude)],
    },
  }));

  const { clusters, supercluster } = useSupercluster({
    points: points,
    bounds: bounds,
    zoom: zoom,
    options: { radius: 100, maxZoom: 12 },
  });

  return (
    <>
      {clusters.map((cluster, index) => {
        const [longitude, latitude] = cluster.geometry.coordinates;

        if (cluster.properties.cluster) {
          const leaves = supercluster.getLeaves(cluster.id, Infinity);
          const clusterMeasurements = leaves.reduce((acc, leaf) => {
            const measurement = leaf.properties.measurement;
            for (let key in measurement) {
              if (acc[key]) {
                acc[key].push(measurement[key]);
              } else {
                acc[key] = [measurement[key]];
              }
            }
            return acc;
          }, {});

          return (
            <MeasurementMarker
              key={`cluster-${cluster.id}`}
              measurement={clusterMeasurements}
              value={aggregate}
              theme={theme}
              formula={formula}
              dataParams={dataParams}
              arrayData={true}
              latLng={[latitude, longitude]}
              markerStyle={"count"}
              // myRef={markerRef}
            />

            // <Marker
            //   key={`cluster-${cluster.id}`}
            //   position={[latitude, longitude]}
            //   icon={fetchIcon(cluster.properties.point_count, 25 + cluster.properties.point_count)}
            //   eventHandlers={{
            //     click: () => {
            //       const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(cluster.id), maxZoom);
            //       map.setView([latitude, longitude], expansionZoom, {
            //         animate: true,
            //       });
            //     },
            //   }}
            // />
          );
        }

        return (
          <MeasurementMarker
            key={index}
            theme={theme}
            formula={formula}
            measurement={cluster.properties.measurement}
            value={aggregate}
            dataParams={dataParams}
            arrayData={false}
          />
        );
      })}
    </>
  );
}

export default CustomMarkerLayer;
