/** @format */

import React, { useState } from "react";
import { Space, Button, Layout, Menu, Flex, Divider, Tabs, Card } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LineChartOutlined,
  ProfileOutlined,
  CloseOutlined,
  CalendarOutlined,
  // CompassOutlined,
  TableOutlined,
  BoxPlotOutlined,
  InfoOutlined,
  CustomerServiceOutlined,
} from "@ant-design/icons";
import Logo from "../assets/images/logo-dark.png";
import { usePrevious } from "../utils/CustomHooks";
import InfoTooltip from "../components/general/InfoTooltip";
import AnalysisOverview from "./analysis/AnalysisOverview";
import AnalysisTimeseriesPlot from "./analysis/AnalysisTimeseriesPlot";
import AnalysisVariancePlot from "./analysis/AnalysisVariancePlot";
import AnalysisCalender from "./analysis/AnalysisCalender";
import AnalysisTable from "./analysis/AnalysisTable";
import InfoPage from "./analysis/InfoPage";
const { Sider } = Layout;

const sidebarItems = [
  {
    key: "analysis",
    label: <Divider style={{ padding: 0, margin: 0 }} />,
    type: "group",
    children: [
      {
        key: "overview",
        icon: <ProfileOutlined />,
        label: (
          <Flex justify="space-between">
            <div style={{ marginRight: 10 }}>Overzicht</div>
            <InfoTooltip content={<div>Overzicht van de geselcteerde sensoren.</div>} />
          </Flex>
        ),
      },
      {
        key: "timeseriesPlot",
        icon: <LineChartOutlined />,
        label: (
          <Flex justify="space-between">
            <div style={{ marginRight: 10 }}>Tijdserie plot</div>
            <InfoTooltip content={<div>Tijdserie plot van de geselcteerde sensoren</div>} />
          </Flex>
        ),
      },
      {
        key: "variancePlot",
        icon: <BoxPlotOutlined />,
        label: (
          <Flex justify="space-between">
            <div style={{ marginRight: 10 }}>Variantie plot</div>
            <InfoTooltip content={<div>Variatieplot van de geselcteerde sensoren.</div>} />
          </Flex>
        ),
      },
      {
        key: "calenderPLot",
        icon: <CalendarOutlined />,
        label: (
          <Flex justify="space-between">
            <div style={{ marginRight: 10 }}>Calender plot</div>
            <InfoTooltip content={<div>Kalenderplot van de geselcteerde sensoren.</div>} />
          </Flex>
        ),
      },
      // {
      //   key: "pollutionRosePlot",
      //   icon: <CompassOutlined />,
      //   label: (
      //     <Flex justify="space-between">
      //       <div>Windroosanalyse</div>

      //       <InfoTooltip content={<div>Windroosplot van de geselcteerde sensoren.</div>} />
      //     </Flex>
      //   ),
      // },
      {
        key: "table",
        icon: <TableOutlined />,
        label: (
          <Flex justify="space-between">
            <div style={{ marginRight: 10 }}>Tabel</div>
            <InfoTooltip content={<div>Tabel van de geselcteerde sensoren.</div>} />
          </Flex>
        ),
      },
    ],
  },
  {
    key: "overig",
    label: <Divider style={{ padding: 0, margin: 0 }} />,
    type: "group",
    children: [
      {
        key: "infoPage",
        icon: <InfoOutlined />,
        label: (
          <Flex justify="space-between">
            <div style={{ marginRight: 10 }}>Info</div>
            <InfoTooltip content={<div>Informatie Pagina.</div>} />
          </Flex>
        ),
      },
      {
        key: "support",
        icon: <CustomerServiceOutlined />,
        label: (
          <Flex justify="space-between">
            <div style={{ marginRight: 10 }}>Support</div>
            <InfoTooltip content={<div>Support.</div>} />
          </Flex>
        ),
      },
    ],
  },
];

function SiderLeft({ theme, formula, dataParams, stations, collapsed, setCollapsed, width, collapsedWidth, useSmall }) {
  /**
   * The sidebar component which shows all the analysis tabs.
   * @param {String} theme - The theme of the data.
   * @param {String} formula - The formula of the data.
   * @param {Object} dataParams - The data parameters.
   * @param {Array} stations - The array of sensor ids.
   * @param {Boolean} collapsed - The state of the sidebar.
   * @param {Function} setCollapsed - The function to set the state of the sidebar.
   * @param {Number} width - The width of the sidebar.
   * @param {Boolean} useSmall - The state of the screen size.
   * @returns The sidebar component.
   */

  // The sider and tab states
  const [activeTab, setActiveTab] = useState(null);

  const handleMenuItemClick = (key) => {
    // In case we do not want to open a tab we simply open the window and return
    if (key === "support") {
      window.open("https://www.stactics.nl/support", "_blank");
      return;
    }

    if (activeTab === key) {
      setActiveTab(null); // Deselect the tab if clicked twice
    } else {
      setActiveTab(key); // Select the tab if it's not already selected
    }
  };

  // If the stations change -> remove the tabs that are not active
  const previousStations = usePrevious(stations);

  // The tab card for the different analysis pages, the keys here should correspond to the keys in the sidebarItems
  const tabCard = (key, label) => {
    // If the stations have changed and the tab is not active we return null so that it get's removed and it does not keep rerendering
    if (key !== activeTab && stations !== previousStations) {
      return null;
    }

    let tab;
    switch (key) {
      case "overview":
        tab = <AnalysisOverview theme={theme} formula={formula} stations={stations} dataParams={dataParams} />;
        break;
      case "timeseriesPlot":
        tab = <AnalysisTimeseriesPlot theme={theme} formula={formula} stations={stations} dataParams={dataParams} />;
        break;
      case "variancePlot":
        tab = <AnalysisVariancePlot theme={theme} formula={formula} stations={stations} dataParams={dataParams} />;
        break;
      case "calenderPLot":
        tab = <AnalysisCalender theme={theme} formula={formula} stations={stations} dataParams={dataParams} />;
        break;
      case "table":
        tab = <AnalysisTable theme={theme} formula={formula} stations={stations} dataParams={dataParams} />;
        break;
      case "infoPage":
        tab = <InfoPage setActiveTab={setActiveTab} />;
        break;
      default:
        tab = null;
    }

    // NOTE we want everything to scale properly se we place an absolute flexbox inside the card 56 pixels from the top as this is the card header height
    return (
      <Card
        title={<Space>{label}</Space>}
        extra={<Button type="text" className="close-button" icon={<CloseOutlined />} onClick={() => handleMenuItemClick(null)} />}
        style={{
          zIndex: 1,
          position: "fixed",
          ...(useSmall ? { inset: 0 } : { left: collapsed ? collapsedWidth + 10 : width + 10, top: 60, right: 40, bottom: 0 }),
          transition: "all 0.2s",
        }}
      >
        <div style={{ position: "absolute", inset: 0, top: 56, overflow: "auto", padding: 20 }}>
          <div style={{ position: "relative", height: "100%", width: "100%" }}>{tab}</div>
        </div>
      </Card>
    );
  };

  // Get all the tabs based on the sidebar items and the tabcards
  function getTabs() {
    const tabs = [];
    for (const item of sidebarItems) {
      if (item.children) {
        for (const child of item.children) {
          tabs.push({
            key: child.key,
            label: child.label,
            children: tabCard(child.key, child.label),
          });
        }
      } else {
        tabs.push({
          key: item.key,
          label: item.label,
          children: tabCard(item.key, item.label),
        });
      }
    }
    return tabs;
  }
  const tabs = getTabs();

  return (
    <Sider
      className="analysis-sidebar"
      width={width}
      collapsedWidth={collapsedWidth}
      theme="light"
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ zIndex: 2, height: "100vh", position: "relative", overflow: "hidden" }}
    >
      <Flex style={{ alignItems: "center", justifyContent: "center" }}>
        {collapsed ? null : <img className="header-logo" src={Logo} alt="unavailable" style={{ flexGrow: 1, paddingLeft: 10, width: "20%" }} />}

        {useSmall ? null : (
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined style={{ fontSize: 25 }} /> : <MenuFoldOutlined style={{ fontSize: 25 }} />}
            onClick={() => setCollapsed(!collapsed)}
            style={{ padding: 30, background: "white" }}
          />
        )}
      </Flex>

      <div style={{ position: "relative" }}>
        <Menu mode="inline" theme="light" items={sidebarItems} onClick={({ key }) => handleMenuItemClick(key)} />
        <Tabs activeKey={activeTab} tabPosition="left" defaultActiveKey="1" items={tabs} style={{ position: "absolute", left: -500 }} />
      </div>

      <div style={{ position: "absolute", bottom: 0, left: 0, paddingLeft: 10 }}>
        <h5>{new Date().getFullYear()} &copy; Meetnet - Stactics B.V.</h5>
      </div>
    </Sider>
  );
}

export default SiderLeft;
