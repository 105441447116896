/** @format */

import React, { useState, useEffect } from "react";
import { Space, Typography, Button, Checkbox, Layout, Menu, Flex } from "antd";
import { DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons";
import InfoTooltip from "./general/InfoTooltip";
const { Sider } = Layout;
const { Title } = Typography;

const SiderRight = ({ dataParams, stations, setStations, useSmall }) => {
  /**
   * This component is a drawer with a menu to select the stations.
   * @param {Object} dataParams - The data parameters.
   * @param {Array} stations - The stations.
   * @param {Function} setStations - The function to set the stations.
   * @param {Boolean} useSmall - The boolean to use a small drawer.
   * @returns The drawer with the menu.
   */

  // Get the dataParams from the context and use them to get the initial value for the idMetaLookup
  const [idMetaLookup, setIdMetaLookup] = useState(dataParams.stations.idMetaLookup);

  // NOTE when the dataParams change the idmetalookup is reset but because of the state this often happens after the dataParams are set
  useEffect(() => {
    setIdMetaLookup(dataParams.stations.idMetaLookup);
  }, [dataParams]);

  const onChange = (e, option) => {
    // Set the idMetaLookup for the internal state of the SiderRight component
    const include = !idMetaLookup[option].include;
    idMetaLookup[option].include = include;
    setIdMetaLookup({ ...idMetaLookup });

    // Remove or add the sensorId to the sensorIds array which is used for external settings
    const index = stations.indexOf(option);

    if (index > -1) {
      if (!include) {
        stations.splice(index, 1);
      }
    } else {
      if (include) {
        stations.push(option);
      }
    }

    setStations([...stations]);
  };

  const onCheckAllChange = (e, property, propertyValue) => {
    // Set the idMetaLookup for the internal state of the SiderRight component and set the new stations
    const newStations = [];

    setIdMetaLookup({
      ...idMetaLookup,
      ...Object.fromEntries(
        Object.entries(idMetaLookup).map(([sensorId, sensorIdInfo]) => {
          const include = sensorIdInfo[property] === propertyValue ? e.target.checked : sensorIdInfo.include;
          if (include) {
            newStations.push(sensorId);
          }
          return [sensorId, { ...sensorIdInfo, include: include }];
        })
      ),
    });

    setStations([...newStations]);
  };

  const handleCheckboxClick = (e) => {
    e.stopPropagation(); // stop the event from propagating to the menu item so we can actually click the checkbox
  };

  const getCheckedIndeterminate = (property, propertyValue) => {
    let checked = true;
    let indeterminate = false;
    for (const sensorIdInfo of Object.values(idMetaLookup)) {
      if (sensorIdInfo[property] === propertyValue) {
        if (sensorIdInfo.include) {
          indeterminate = true;
        } else {
          checked = false;
        }
      }
    }

    indeterminate = checked ? false : indeterminate;

    return [checked, indeterminate];
  };

  const items = [
    {
      key: "networks",
      label: (
        <Flex>
          <div style={{ marginRight: 5 }}>Networks</div>
          <InfoTooltip content={<div>Selecteed meetstation gebasseerd op uit welk netwerk ze komen.</div>} />
        </Flex>
      ),
      type: "group",
      children: [
        ...Object.entries(dataParams.stations.networkLookup).map(([networkName, networkSensors]) => {
          const [checked, indeterminate] = getCheckedIndeterminate("network", networkName);
          return {
            key: networkName,
            label: (
              <Checkbox
                network={networkName}
                checked={checked}
                indeterminate={indeterminate}
                onClick={handleCheckboxClick}
                onChange={(e) => onCheckAllChange(e, "network", networkName)}
              >
                {networkName}
              </Checkbox>
            ),
            children: [
              ...networkSensors.map((sensorId) => {
                const sensorInfo = idMetaLookup[sensorId];
                return {
                  key: networkName + sensorId,
                  label: (
                    <Checkbox network={networkName} checked={sensorInfo ? sensorInfo.include : false} onChange={(e) => onChange(e, sensorId)}>
                      {sensorInfo ? sensorInfo.name : null}
                    </Checkbox>
                  ),
                };
              }),
            ],
          };
        }),
      ],
    },

    {
      key: "regios",
      label: (
        <Flex>
          <div style={{ marginRight: 5 }}>Regios</div>
          <InfoTooltip content={<div>Selecteed meetstation gebasseerd op de geografische ligging.</div>} />
        </Flex>
      ),
      type: "group",
      children: [
        ...Object.entries(dataParams.stations.countryLookup).map(([countryName, provinceLookup]) => {
          const [checked, indeterminate] = getCheckedIndeterminate("country", countryName);

          // We also need to make sure that if the countryName is empty we set it to "unknown", we do the same for all the other keys
          countryName = countryName === "" ? "unknown" : countryName;

          return {
            key: countryName,
            label: (
              <Checkbox
                network={countryName}
                checked={checked}
                indeterminate={indeterminate}
                onClick={handleCheckboxClick}
                onChange={(e) => onCheckAllChange(e, "country", countryName)}
              >
                {countryName}
              </Checkbox>
            ),
            children: [
              ...Object.entries(provinceLookup).map(([provinceName, municipalityLookup]) => {
                const [checked, indeterminate] = getCheckedIndeterminate("province", provinceName);
                provinceName = provinceName === "" ? "unknown" : provinceName;

                return {
                  key: countryName + provinceName,
                  label: (
                    <Checkbox
                      network={provinceName}
                      checked={checked}
                      indeterminate={indeterminate}
                      onClick={handleCheckboxClick}
                      onChange={(e) => onCheckAllChange(e, "province", provinceName)}
                    >
                      {provinceName}
                    </Checkbox>
                  ),
                  children: [
                    ...Object.entries(municipalityLookup).map(([municipalityName, sensorIds]) => {
                      const [checked, indeterminate] = getCheckedIndeterminate("municipality", municipalityName);
                      municipalityName = municipalityName === "" ? "unknown" : municipalityName;

                      return {
                        key: countryName + provinceName + municipalityName,
                        label: (
                          <Checkbox
                            network={municipalityName}
                            checked={checked}
                            indeterminate={indeterminate}
                            onClick={handleCheckboxClick}
                            onChange={(e) => onCheckAllChange(e, "municipality", municipalityName)}
                          >
                            {municipalityName}
                          </Checkbox>
                        ),
                        children: [
                          ...sensorIds.map((sensorId) => {
                            const sensorInfo = idMetaLookup[sensorId];
                            return {
                              key: countryName + provinceName + municipalityName + sensorId,
                              label: (
                                <Checkbox network={"temp"} checked={sensorInfo ? sensorInfo.include : false} onChange={(e) => onChange(e, sensorId)}>
                                  {sensorId}
                                </Checkbox>
                              ),
                            };
                          }),
                        ],
                      };
                    }),
                  ],
                };
              }),
            ],
          };
        }),
      ],
    },
  ];

  // Menu settings
  const getLevelKeys = (items1) => {
    const key = {};
    const func = (items2, level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };
    func(items1);
    return key;
  };

  const levelKeys = getLevelKeys(items);
  const [stateOpenKeys, setStateOpenKeys] = useState([]);
  const onOpenChange = (openKeys) => {
    const currentOpenKey = openKeys.find((key) => stateOpenKeys.indexOf(key) === -1);

    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys.filter((key) => key !== currentOpenKey).findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);
      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex) // remove repeat key
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]) // remove current level all child
      );
    } else {
      setStateOpenKeys(openKeys); // close
    }
  };

  // Drawer positioning based on the right prop which is either left or right
  const [collapsed, setCollapsed] = useState(true);
  const collapsedWidth = 30;
  const width = useSmall ? 300 : 400;

  return (
    <Sider
      className="location-drawer"
      width={width}
      collapsedWidth={collapsedWidth}
      theme="light"
      trigger={null}
      collapsible
      collapsed={collapsed}
      // NOTE this one needs to be position fixed as to not have a problem with the other sider on mobile devices
      style={{ zIndex: 2, position: "fixed", top: 0, right: 0, bottom: 0, background: "none", pointerEvents: "none" }}
    >
      <Flex style={{ flexGrow: 1, height: "100%", alignItems: "top", justifyContent: "left" }}>
        <Flex className="location-drawer-control" vertical style={{ alignItems: "center", justifyContent: "center" }}>
          <Button
            className="location-drawer-control-button"
            type="text"
            icon={collapsed ? <DoubleLeftOutlined /> : <DoubleRightOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "16px",
              width: collapsedWidth,
              height: 40,
              backgroundColor: "white",
              pointerEvents: "auto",
            }}
          />
        </Flex>
        <Flex
          vertical
          style={{ flexGrow: 1, height: "100%", alignItems: "top", justifyContent: "left", backgroundColor: "white", overflowY: "auto", pointerEvents: "auto" }}
        >
          <Title level={2} style={{ marginLeft: 20, marginTop: 10 }}>
            <Space>
              <div>Meetstations</div>
              <InfoTooltip content={<div>Selecteer de meetstations die je wil laten zien op de map en voor de berekeningen.</div>} fontSize={20} />
            </Space>
          </Title>
          <Menu mode="inline" defaultSelectedKeys={["231"]} openKeys={stateOpenKeys} onOpenChange={onOpenChange} items={items} />
        </Flex>
      </Flex>
    </Sider>
  );
};
export default SiderRight;
