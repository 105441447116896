/** @format */

import React, { useContext, useEffect } from "react";
import { Row, Col, Space, Select, ConfigProvider, DatePicker, Card, Switch } from "antd";
import { DataContext } from "../../utils/Context";
import dayjs from "dayjs";
import InfoTooltip from "../general/InfoTooltip";
const { RangePicker } = DatePicker;

function DataOptionsMenu({ options, optionsRange, dataOptions, setDataOptions, setStep, combine, setCombine }) {
  /**
   * This component is a generic options menu that takes an array of options and returns a menu with the options.
   * @param {Array} options - The options to be displayed in the menu.
   * @param {Object} optionsRange - The range of options.
   * @param {Object} dataOptions - The data options.
   * @param {Function} setDataOptions - The function to set the data options.
   * @param {Function} setStep - The function to set the step.
   * @param {Boolean} combine - Weather to combine the data from the sensors or not.
   * @param {Function} setCombine - The function to set the combine state.
   * @returns The options menu.
   */

  const onChange = (value, label) => {
    setDataOptions({ ...dataOptions, [label]: value });
    if (setStep) setStep(0);
  };

  const handleDateChange = (dates, dateStrings) => {
    dataOptions = { ...dataOptions, range: dates };
    setDataOptions(dataOptions);
    if (setStep) setStep(0);
  };

  // If the refresh changes it means we need to set the date to the current time
  // NOTE that it say dependencies are mising but it functions the way it should
  const { refresh } = useContext(DataContext);
  useEffect(() => {
    handleDateChange([dataOptions.range[0], dayjs()]);
  }, [refresh]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Select: {
            fontSize: 11,
            optionFontSize: 11,
          },
          DatePicker: {
            fontSize: 11,
            inputFontSize: 11,
          },
        },
      }}
    >
      <Card
        style={{
          pointerEvents: "auto",
        }}
        styles={{
          body: {
            padding: 5,
          },
        }}
      >
        <Row gutter={[20, 5]} justify="center">
          <Col>
            <Space>
              <RangePicker showTime size={"middle"} value={dataOptions.range} onChange={handleDateChange} presets={[...optionsRange.options]} />
              <InfoTooltip content={<div>{optionsRange.description}</div>} />
            </Space>
          </Col>

          {options.map((options, index) => {
            return (
              <Col key={options.label + index}>
                <Space>
                  <Select
                    showSearch
                    placeholder=""
                    optionFilterProp=""
                    onChange={(value) => onChange(value, options.label)}
                    filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                    options={options.options}
                    key={options.label + index}
                    size={"small"}
                    value={dataOptions[options.label]}
                  />

                  <InfoTooltip content={<div>{options.description}</div>} />
                </Space>
              </Col>
            );
          })}

          {setCombine ? (
            <Col>
              <Space>
                <Switch
                  checked={combine}
                  onChange={() => {
                    setCombine(!combine);
                  }}
                />
                <div>Combine Sensors</div>
                <InfoTooltip
                  content={<div>Combineer de waardes van de sensoren tot een enkele waarde. Het geselcteerde aggregaat word hiervoor genomen.</div>}
                />
              </Space>
            </Col>
          ) : null}
        </Row>
      </Card>
    </ConfigProvider>
  );
}

export default DataOptionsMenu;
