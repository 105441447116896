/** @format */
import dayjs from "dayjs";

async function getInitialDataParams(theme) {
  // Get the meta information for the stations and set the initial dataParams
  const stations = await getStations(theme);
  const components = await getComponents(theme);
  const options = await getOptions(theme, components);

  const dataParams = {
    theme,
    stations: { ...stations },
    components: { ...components },
    options: { ...options },
    defaults: {
      // the default stations are all the stations
      stations: Object.keys(stations.idMetaLookup),

      // The default options are the ones selected as default
      options: Object.keys(options).reduce((acc, key) => {
        const option = options[key];
        acc[option.label] = option.default;
        return acc;
      }, {}),
    },
  };

  return dataParams;
}

async function getStations(theme) {
  /**
   * This function retrieves the stations from the API and returns the stations.
   * @param {String} theme - The theme of the stations.
   * @returns The stations with the following structure:
   * stations = {
   *  networkLookup: {
   *   network: [sensor_id1, sensor_id2, ...]
   *  },
   *  countryLookup: {
   *   country: {
   *    province: {
   *     municipality: [sensor_id1, sensor_id2, ...]
   *    }
   *   }
   *  },
   *  idMetaLookup: {
   *   sensor_id: {
   *    sensor_id: "sensor_id",
   *    name: "name",
   *    description: "description",
   *    country: "country",
   *    province: "province",
   *    municipality: "municipality",
   *    network: "network",
   *    latitude: latitude,
   *    longitude: longitude
   *   }
   *  }
   * }
   */

  // Fetch the stations from the API and deine the stations object
  const responseStations = await fetch(`${apiURL}/${theme}/get?table=stations`);
  const dataStations = await responseStations.json();

  const stations = {
    networkLookup: {},
    countryLookup: {},
    idMetaLookup: {},
  };

  for (const obj of dataStations) {
    (stations.networkLookup[obj.network] ??= []).push(obj.sensor_id);

    // Lookup by country, province, municipality
    stations.countryLookup[obj.country] ??= {};
    stations.countryLookup[obj.country][obj.province] ??= {};
    stations.countryLookup[obj.country][obj.province][obj.municipality] ??= [];

    // Lookup by network
    stations.countryLookup[obj.country][obj.province][obj.municipality].push(obj.sensor_id);

    // Lookup by sensor id with meta information, both for state and for the current selection, note that the include is there for the location drawer
    stations.idMetaLookup[obj.sensor_id] = { ...obj, include: true };
  }

  return stations;
}

async function getComponents(theme) {
  /**
   * This functions retrieves the components from the API and returns an object with the following structure:
   * components = {
   *  formula: {
   *    formula: "formula",
   *    unit: "unit",
   *    description: "description",
   *    color_ranges: [
   *     {
   *      min: min,
   *      max: max,
   *      color: color
   *     }
   *  }
   * }
   */

  const responseComponents = await fetch(`${apiURL}/${theme}/get?table=components`);
  const dataComponents = await responseComponents.json();

  const components = dataComponents.reduce((acc, component) => {
    acc[component.formula] = component;
    return acc;
  }, {});

  return components;
}

async function getOptions(theme, components) {
  const sortedEntries = Object.entries(components).sort(([, a], [, b]) => b.priority - a.priority);
  const componentsOptions = sortedEntries.map(([key, value]) => {
    return {
      value: key,
      label: `${value.formula}`,
    };
  });

  const optionsFormula = {
    label: "formula",
    description: "Welk component je wilt bekijken.",
    default: componentsOptions[0].value,
    options: componentsOptions,
  };

  // Set the default options based on the theme
  console.log("theme", theme);
  if (theme === "geluid" || theme === "sound") {
    optionsRange.default = [dayjs().add(-1, "h"), dayjs()];
    optionsWindowsize.default = "15m";
  } else {
    optionsRange.default = [dayjs().add(-24, "h"), dayjs()];
    optionsWindowsize.default = "1h";
    optionsAggregate.default = "last";
  }

  const options = {
    optionsRange,
    optionsFormula,
    optionsAggregate,
    optionsInterval,
    optionsInterpolate,
    optionsError,
    optionsTimeperiods,
    optionsWindowsize,
  };

  return options;
}

// All the available options predefined options for fetching data
const optionsRange = {
  label: "range",
  description: "De tijdsrange die bepaalt tussen welke start en einddatum de data moet liggen die je op wilt halen.",
  default: [dayjs().add(-24, "h"), dayjs()],
  options: [
    {
      label: "Last 1 Minute",
      value: [dayjs().add(-1, "m"), dayjs()],
    },
    {
      label: "Last 30 Minutes",
      value: [dayjs().add(-30, "m"), dayjs()],
    },
    {
      label: "Last 1 Hour",
      value: [dayjs().add(-1, "h"), dayjs()],
    },
    {
      label: "Last 6 Hours",
      value: [dayjs().add(-6, "h"), dayjs()],
    },
    {
      label: "Last 12 Hours",
      value: [dayjs().add(-12, "h"), dayjs()],
    },
    {
      label: "Last 24 Hours",
      value: [dayjs().add(-24, "h"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 14 Days",
      value: [dayjs().add(-14, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "Last 3 Months",
      value: [dayjs().add(-3, "M"), dayjs()],
    },
    {
      label: "Last 6 Months",
      value: [dayjs().add(-6, "M"), dayjs()],
    },
    {
      label: "Last 1 Year",
      value: [dayjs().add(-1, "y"), dayjs()],
    },
    {
      label: "Last 2 Years",
      value: [dayjs().add(-2, "y"), dayjs()],
    },
  ],
};

const optionsInterval = {
  label: "interval",
  description: "De grote van de buckets, in andere woorden de grootte van de stapjes van de tijdsopdeling.",
  default: "1h",
  options: [
    {
      value: "30s",
      label: "Elke 30 seconden",
    },
    {
      value: "15m",
      label: "Elke 15 minuten",
    },
    {
      value: "30m",
      label: "Elke 30 minuten",
    },
    {
      value: "1h",
      label: "Elk 1 uur",
    },
    {
      value: "6h",
      label: "Elke 6 uur",
    },
    {
      value: "1d",
      label: "Elke 1 dag",
    },
    {
      value: "1w",
      label: "Elk 1 week",
    },
    {
      value: "1mon",
      label: "Elk 1 maand",
    },
    {
      value: "1y",
      label: "Elk 1 jaar",
    },
  ],
};

const optionsInterpolate = {
  label: "interpolate",
  description: "Hoe missende data moet worden behandeld. Dit word voornamelijk gedaan om gaten op te vullen.",
  default: "none",
  options: [
    {
      value: "none",
      label: "Geen interpolatie",
    },
    {
      value: "lastObserved",
      label: "Vorige waarde",
    },
  ],
};

const optionsAggregate = {
  label: "aggregate",
  description: "Hoe de data geagregeerd moet worden om van meerdere meetingen tot een enkel punt te komen.",
  default: "average",
  options: [
    {
      value: "average",
      label: "Gemiddelde waarde",
    },
    {
      value: "last",
      label: "Laatste waarde",
    },
    {
      value: "first",
      label: "Eerste waarde",
    },
    {
      value: "min",
      label: "Minimum waarde",
    },
    {
      value: "max",
      label: "Maximum waarde",
    },
  ],
};

const optionsError = {
  label: "error",
  description: "Welke aggregatie van de data moet worden gebruikt om de errors te laten zien",
  default: "stddev",
  options: [
    {
      value: "stddev",
      label: "Standaard afwijking",
    },
    {
      value: "variance",
      label: "Variantie",
    },
    {
      value: "min",
      label: "Minimum waarde",
    },
    {
      value: "max",
      label: "Maximum waarde",
    },
    // {
    //   value: "skewness",
    //   label: "Skewness",
    // },
    // {
    //   value: "kurtosis",
    //   label: "kurtosis",
    // },
  ],
};

const optionsTimeperiods = {
  label: "timeperiods",
  description: "De tijdsperiodes waarin de data gegroepeerd moet worden.",
  default: "isodow",
  order: ["month", "week", "day", "isodow", "hour"],
  options: [
    {
      value: "hour",
      label: "Uur van de dag",
    },
    {
      value: "isodow",
      label: "Dag van de week",
    },
    {
      value: "day",
      label: "Dag van de maand",
    },
    {
      value: "week",
      label: "Week van het jaar",
    },
    {
      value: "month",
      label: "Maand van het jaar",
    },
  ],
};

const optionsWindowsize = {
  label: "windowsize",
  description: "De tijdsgrootte van het bewegende venster.",
  default: "1h",
  options: [
    {
      value: "3m",
      label: "3 minuten",
    },
    {
      value: "5m",
      label: "5 minuten",
    },
    {
      value: "15m",
      label: "15 minuten",
    },
    {
      value: "30m",
      label: "30 minuten",
    },
    {
      value: "1h",
      label: "1 uur",
    },
    {
      value: "6h",
      label: "6 uur",
    },
    {
      value: "1d",
      label: "24 uur",
    },
    {
      value: "1w",
      label: "1 week",
    },
    {
      value: "1mon",
      label: "1 maand",
    },
  ],
};

async function getMeasurements({ theme, range, formula, sensorIdsChecked, aggregates, bucket, timeperiods, window, combine, array_agg, meta, enrich }) {
  /**
   * This function retrieves the measurements from the API and returns the measurements
   * @param {String} theme - The theme of the measurements.
   * @param {Array} range - The range of the measurements.
   * @param {String} formula - The formula of the measurements.
   * @param {Array} sensorIdsChecked - The sensor ids of the measurements.
   * @param {Array} aggregates - The aggregates of the measurements.
   * @param {Object} bucket - The bucket of the measurements.
   * @param {Array} timeperiods - The timeperiods of the measurements.
   * @param {Object} window - The window of the measurements.
   * @param {Boolean} combine - The combine of the measurements.
   * @param {Boolean} array_agg - The array_agg of the measurements.
   * @param {Boolean} meta - The meta of the measurements.
   * @param {Object} idMetaLookup - The idMetaLookup of the measurements.
   * @param {Boolean} includeAllIds - The includeAllIds of the measurements.
   * @returns The measurements with the following structure unless an enrich object is provided:
   * [
   *  {
   *    sensor_id: "sensor_id",
   *    formula: "formula",
   *    network: "network",
   *    datetime: "datetime" or [datetime1, datetime2, ...],
   *    aggregate1: value or [value1, value2, ...],
   *    aggregate2: value or [value1, value2, ...],
   *    aggregatex: value or [value1, value2, ...],
   *  }
   * ]
   */

  // Only make the request if the necessary parameters are available
  if (theme && range && formula) {
    // Construct the request url and make the request
    const requestURL = `
      ${apiURL}/${theme}/measurements
        ?filters=[
          datetime_gt=${range[0].$d.toISOString()},
          datetime_lt=${range[1].$d.toISOString()},
          c.formula_eq=${formula},
          s.sensor_id_in=[${sensorIdsChecked.join(",")}]
        ]
        ${aggregates ? `&aggregates=[${aggregates}]` : ""}
        ${window ? `&window=[size=${window.size}]` : ""}
        ${
          bucket
            ? `
          &bucket=[
            interval=${bucket.interval},
            gapfill=${bucket.interval},
            interpolate=${bucket.interpolate === "lastObserved" ? "true" : "false"}
          ]`
            : ""
        }
        ${timeperiods ? `&timeperiods=[${timeperiods}]` : ""}
        &array_agg=${array_agg}
        &combine=${combine}
        &meta=${meta}
      `
      .replace(/\s+/g, "")
      .trim();

    console.log("requestURL", requestURL);
    const response = await fetch(requestURL);
    let measurements = await response.json();

    // If enrich is provided, enrich the measurements else just return them
    if (enrich && !combine) {
      return enrichMeasurements({ formula, measurements, sensorIdsChecked, aggregates, timeperiods, ...enrich });
    } else {
      return measurements;
    }
  }

  return [];
}

function enrichMeasurements({ formula, measurements, sensorIdsChecked, aggregates, timeperiods, includeAllIds, idMetaLookup }) {
  /**
   * This function enriches the measurements with the meta information and returns the enriched measurements and checks their availability and if they are
   * supposed to be included.
   * @param {Array} measurements - The measurements.
   * @param {Array} sensorIdsChecked - The sensor ids of the measurements.
   * @param {Boolean} includeAllIds - The includeAllIds of the measurements.
   * @param {Object} idMetaLookup - The idMetaLookup of the measurements.
   * @returns The measurements with the following structure:
   * [
   *  {
   *   sensor_id: "sensor_id",
   *   formula: "formula",
   *   unit: "unit"
   *   name: "name",
   *   description: "description",
   *   country: "country",
   *   province: "province",
   *   municipality: "municipality",
   *   network: "network",
   *   isAvailable: true/false,
   *   include: true/false,
   *   latitute: latitude,
   *   longitude: longitude,
   *   datetime: "datetime" or [datetime1, datetime2, ...],
   *   aggregate1: value or [value1, value2, ...],
   *   aggregate2: value or [value1, value2, ...],
   *   aggregatex: value or [value1, value2, ...],
   *  }
   * ]
   */

  // First we need to obtain the meta information from all the sensor ids that are supposed to be included
  const returnIds = includeAllIds ? Object.keys(idMetaLookup) : sensorIdsChecked;
  const defaultAggregates = aggregates ? aggregates.reduce((acc, aggregate) => ({ ...acc, [aggregate]: null }), {}) : {};
  const defaultTimeperiods = timeperiods ? timeperiods.reduce((acc, timeperiod) => ({ ...acc, [timeperiod]: null }), {}) : {};

  // Create an object with all the sensor ids that are supposed to be included and the meta information, but exclude the sensors that don't have the formula
  const finalMeasurements = {};
  for (const sensor_id of returnIds) {
    if (idMetaLookup[sensor_id].formula && idMetaLookup[sensor_id].formula.includes(formula)) {
      finalMeasurements[sensor_id] = {
        ...idMetaLookup[sensor_id],
        ...defaultAggregates,
        ...defaultTimeperiods,
        datetime: null,
        isAvailable: false,
        include: false,
      };
    }
  }

  // Then we add the data from the actual fetched measurements
  for (const measurement of measurements) {
    finalMeasurements[measurement.sensor_id] = {
      ...finalMeasurements[measurement.sensor_id],
      ...measurement,
      isAvailable: true,
    };
  }

  // Finally we set the include status for all the sensor ids that are supposed to be included to true
  for (const sensor_id of sensorIdsChecked) {
    if (finalMeasurements[sensor_id]) {
      finalMeasurements[sensor_id].include = true;
    }
  }

  // We then return the measurements again as an array
  return Object.values(finalMeasurements);
}

let apiURL = "";
if (process.env.NODE_ENV === "development") {
  apiURL = "http://localhost:5000";
} else if (process.env.NODE_ENV === "production") {
  apiURL = "https://api.meetnet.stactics.nl";
} else {
  console.log("Unknown environment");
}

export { apiURL, getMeasurements, getInitialDataParams };
