/** @format */

import React from "react";
import Tour from "../guide/Tour";
import { Row, Col, Space, Divider } from "antd";

const InfoPage = ({ setActiveTab }) => {
  /**
   * This component is the info page that shows general information about the dashboard.
   * @param {function} setActiveTab - The function to set the active tab so it can be closed if start tour is clicked
   * @returns The info page component
   */

  return (
    <Row style={{ paddingLeft: 10, paddingTop: 0 }}>
      <Col span={24}>
        <h1 style={{ marginBottom: 0 }}>Info Pagina</h1>

        <Row>
          <Col>
            <p>LET OP: Vanaf vrijdag 19 juli om 18:15 uur is er een wijziging geweest in de methodiek voor de correcties van geluidsmetingen. Hierdoor is er een wijziging in geluidniveaus zichtbaar.</p>
            <p>Deze pagina laat algemene informatie zien over het meetnet dashboard. Je kan ook de tour nemen voor een korte inleiding:</p>

            <Tour
              callbackStart={() => {
                setActiveTab(null);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>De map</h2>
            <p>Op de map zijn meetpunten te zien van verschillende meetstations.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>De analyses</h2>
            <p>Er zijn verscheidene analyses beschikbaar. Hiervan kunnen ook meerdere parameters worden ingesteld.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Overig</h2>
            <p></p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InfoPage;
