/** @format */

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FloatButton } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import { Wind } from "react-feather";

function ThemeSelector({ theme }) {
  /**
   * This component is used to select the theme (air or sound) and navigate to the corresponding page.
   * @param {String} theme - The selected theme.
   * @returns The theme selector.
   */

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  return (
    <FloatButton.Group
      className="theme-selector"
      // trigger="hover"
      open={open}
      trigger="click"
      type="primary"
      style={{
        position: "static",
        // top: 10,
        // right: 30,
        inset: 0,
        height: 0,
        display: "flex",
        flexDirection: "column-reverse", // change direction to downwards
        justifyContent: "start",
        alignItems: "center",
        // zIndex: -1,
        pointerEvents: "auto",
      }}
      icon={theme === "air" ? <Wind size={20} /> : <SoundOutlined />}
      onClick={() => setOpen(!open)}
    >
      <div style={{ position: "relative", marginTop: 10, marginBottom: 10 }}>
        <div style={{ position: "absolute", left: -45, top: "50%", transform: "translateY(-50%)", fontSize: 14, fontWeight: "bold" }}>Lucht</div>
        <FloatButton
          onClick={() => {
            setOpen(false);
            navigate("/lucht", { replace: false }); // NOTE we without replace: true we would be redirected which could also be an option
          }}
          icon={<Wind size={20} />}
        />
      </div>

      <div style={{ position: "relative", marginTop: 10, marginBottom: 10 }}>
        <div style={{ position: "absolute", left: -50, top: "50%", transform: "translateY(-50%)", fontSize: 14, fontWeight: "bold" }}>Geluid</div>
        <FloatButton
          onClick={() => {
            setOpen(false);
            navigate("/geluid", { replace: false });
          }}
          icon={<SoundOutlined size={20} />}
        />
      </div>
    </FloatButton.Group>
  );
}

export default ThemeSelector;
