/** @format */

import React, { useState, useEffect } from "react";
import { Row, Col, Space, Select } from "antd";
import { getMeasurements } from "../../utils/API";
import { createComparator } from "../../utils/General";
import DataOptionsMenu from "../settings/DataOptionsMenu";
import VarianceChart from "../charts/VarianceChart";
import InfoTooltip from "../general/InfoTooltip";

const AnalysisGraphTimeseries = ({ theme, formula, stations, dataParams }) => {
  /**
   * This component is used to display a timeseries graph for the selected sensors.
   * @param {Array} stations - The array of sensor ids.
   * @param {String} graphType - The type of graph which is either "line" or "bar".
   * @returns The timeseries graph.
   */

  // Get the idMetaLookup, selected options and measurements
  const idMetaLookup = dataParams.stations.idMetaLookup;
  const [dataOptions, setDataOptions] = useState(dataParams.defaults.options);
  const [combine, setCombine] = useState(true);
  const [timeperiods, setTimeperiods] = useState(["isodow", "hour"]);
  const maxStations = 20;

  const { range, aggregate, error } = dataOptions;

  // Fetch the measurments for the selected sensors
  let [measurements, setMeasurements] = useState([]);
  useEffect(() => {
    const loadMeasurements = async () => {
      console.log("fetching measurements AnalysisVariancePLot");
      let measurements = await getMeasurements({
        theme,
        range,
        formula,
        sensorIdsChecked: stations.slice(0, maxStations),
        aggregates: [aggregate, error],
        bucket: null,
        timeperiods,
        window: null,
        combine,
        array_agg: true,
        meta: false,
        enrich: { idMetaLookup, includeAllIds: false },
      });
      setMeasurements(measurements);
    };

    loadMeasurements();
  }, [theme, stations, idMetaLookup, aggregate, error, formula, range, timeperiods, combine]);
  // console.log("measurements", measurements);

  // Return the timeseries graph with the options menu
  return (
    <Row>
      <Col span={24}>
        <Row gutter={[20, 5]} align="middle" style={{ marginBottom: 10 }}>
          <Col>
            <DataOptionsMenu
              options={[dataParams.options.optionsError, dataParams.options.optionsAggregate]}
              optionsRange={dataParams.options.optionsRange}
              dataOptions={dataOptions}
              setDataOptions={setDataOptions}
              setStep={null}
              combine={combine}
              setCombine={setCombine}
            />
          </Col>

          <Col>
            <Space>
              <Select
                mode="multiple"
                showSearch
                placeholder=""
                optionFilterProp=""
                onChange={(value) => setTimeperiods(value.sort(createComparator(dataParams.options.optionsTimeperiods.order)))}
                filterOption={(input, option) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
                options={dataParams.options.optionsTimeperiods.options}
                size={"small"}
                value={timeperiods}
              />
              <InfoTooltip content={<div>{dataParams.options.optionsTimeperiods.description}</div>} />
            </Space>
          </Col>
        </Row>

        <Row>
          {stations.length > maxStations ? (
            <Col span={24}>
              <h5>
                Showing first {maxStations} of {stations.length} stations
              </h5>
            </Col>
          ) : (
            ""
          )}
        </Row>

        <Row>
          <Col span={24}>
            <VarianceChart
              measurements={measurements}
              value={aggregate}
              formula={formula}
              dataParams={dataParams}
              error={error}
              timeperiods={timeperiods}
              graphType={"line"}
              graphName={""}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default AnalysisGraphTimeseries;
