/** @format */

import { SweepDirection } from "igniteui-react-core";
import { IgrRadialGauge } from "igniteui-react-gauges";
import { IgrRadialGaugeModule } from "igniteui-react-gauges";
import { IgrRadialGaugeRange } from "igniteui-react-gauges";
import { RadialGaugeBackingShape } from "igniteui-react-gauges";
import { RadialGaugeNeedleShape } from "igniteui-react-gauges";
import { RadialGaugePivotShape } from "igniteui-react-gauges";
import { RadialGaugeScaleOversweepShape } from "igniteui-react-gauges";
IgrRadialGaugeModule.register();

function GaugeChart({ value, formula, dataParams }) {
  /**
   * This component creates a gauge chart based on the value and formula.
   * Source: https://www.infragistics.com/products/ignite-ui-react/react/components/radial-gauge
   * @param {Number} value - The value of the gauge.
   * @param {String} formula - The formula of the gauge.
   * @returns The gauge chart.
   */

  // Get the color ranges for the formula
  const componentInfo = dataParams.components[formula];
  const { color_ranges } = componentInfo ? componentInfo : {};

  if (!color_ranges) {
    return null;
  }

  const interval = ((color_ranges[0].max - color_ranges[0].min) * 1.5).toFixed(0);

  return (
    <div style={{ position: "relative", height: "20vh", width: "100%", margin: 0, padding: 0 }}>
      <IgrRadialGauge
        height="100%"
        width="100%"
        minimumValue={color_ranges[0].min}
        maximumValue={color_ranges[color_ranges.length - 1].max}
        value={value}
        interval={interval}
        // setting appearance of labels
        labelInterval={interval}
        labelExtent={0.71}
        font={"9px Verdana,Arial"}
        // setting custom needle
        isNeedleDraggingEnabled={true}
        needleEndExtent={0.5}
        needleShape={RadialGaugeNeedleShape.Triangle}
        needleEndWidthRatio={0.03}
        needleStartWidthRatio={0.05}
        needlePivotShape={RadialGaugePivotShape.CircleOverlay}
        needlePivotWidthRatio={0.15}
        needleBaseFeatureWidthRatio={0.15}
        needleBrush={"#79797a"}
        needleOutline={"#79797a"}
        needlePivotBrush={"#79797a"}
        needlePivotOutline={"#79797a"}
        // setting appearance of major/minor ticks
        minorTickCount={4}
        minorTickEndExtent={0.625}
        minorTickStartExtent={0.6}
        minorTickStrokeThickness={1}
        minorTickBrush={"#79797a"}
        tickStartExtent={0.6}
        tickEndExtent={0.65}
        tickStrokeThickness={2}
        tickBrush={"#79797a"}
        // setting extent of gauge scale
        scaleStartAngle={120}
        scaleEndAngle={60}
        scaleBrush={"#d6d6d6"}
        scaleOversweepShape={RadialGaugeScaleOversweepShape.Fitted}
        scaleSweepDirection={SweepDirection.Clockwise}
        scaleEndExtent={0.57}
        scaleStartExtent={0.5}
        // setting appearance of backing dial
        backingBrush={"#fcfcfc"}
        backingOutline={"#d6d6d6"}
        backingStrokeThickness={0}
        backingShape={RadialGaugeBackingShape.Circular}
      >
        {color_ranges.map((range, index) => {
          return (
            <IgrRadialGaugeRange
              key={index}
              startValue={range.min}
              endValue={range.max}
              innerStartExtent={0.5}
              innerEndExtent={0.5}
              outerStartExtent={0.57}
              outerEndExtent={0.57}
              brush={range.color}
            />
          );
        })}
      </IgrRadialGauge>
    </div>
  );
}

export default GaugeChart;
