/** @format */

import { useRef, useEffect } from "react";

const usePrevious = (value) => {
  /**
   * This custom hook is used to get the previous value of a variable. (https://phuoc.ng/collection/react-ref/save-the-previous-value-of-a-variable/).
   * Essentially the previous value is stored in a ref and updated every time the value changes but because useEffect is asynchronous the previous value is
   * always one step behind the current value, ergo the previous value.
   */

  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export { usePrevious };
