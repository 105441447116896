/** @format */

import React, { useState } from "react";
import { Button } from "antd";
import Joyride from "react-joyride";

function Tour({ callbackStart, callbackEnd }) {
  /**
   * This component shows a tour of the website. It uses the react-joyride library to show the tour.
   * The tour consists of a few steps, each with a target and content.
   * @param {function} callbackStart - A callback function that is called when the tour starts.
   * @param {function} callbackEnd - A callback function that is called when the tour ends.
   */

  const [run, setRun] = useState(false);

  const steps = [
    {
      target: ".theme-selector",
      content: "Je kan met deze knop wisselen tussen lucht en geluid.",
      disableBeacon: true,
    },

    {
      target: ".formula-selector",
      content: "Vervolgens kan je selecteren welke waarden je wilt zien.",
    },

    {
      target: ".location-drawer-control-button",
      content: "In dit menu kan je de locaties selecteren die je wilt zien op de kaart en de analyses.",
      placement: "left",
    },

    {
      target: ".map-options-menu",
      content: "Met deze opties kan je de instellingen van de map veranderen.",
    },

    {
      target: ".custom-clock",
      content:
        "De timer laat zien hoelang het duurt voordat hij de data ververgst, deze kan je aan of uitzetten door erop te klikken of nu opniewe verversen door op het icoontje te klikken.",
    },

    {
      target: ".leaflet-control-layers-toggle",
      content: "Eventuele mapstijlen kan je hier selecteren. Deze hebben geen invloed op de data die je ziet.",
    },

    {
      target: ".analysis-sidebar",
      content:
        "Alle analyses zijn hier te vinden, je kan deze balk ingeklapt laten of uitklappen. De grafieken kan je ook verplaatsen door ze vast te pakken en te slepen, en inzoomen door met je muiswiel te bewegen.",
      placement: "right",
    },
  ];

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          if (callbackStart) {
            callbackStart();
          }
          setRun(true);
        }}
      >
        Start Tour
      </Button>
      <Joyride
        steps={steps}
        run={run}
        continuous={true}
        showSkipButton={true}
        showProgress={true}
        disableOverlay={false}
        styles={{
          options: {
            zIndex: 10000,
          },
          overlay: {
            height: "100%",
          },
        }}
        callback={(data) => {
          if (data.status === "finished" || data.status === "skipped") {
            setRun(false);
          }
        }}
        locale={{
          back: "Terug",
          close: "Sluiten",
          last: "Laatste",
          next: "Volgende",
          skip: "Overslaan",
        }}
      />
    </>
  );
}

export default Tour;
