/** @format */

const stripedBackgroundPlugin = {
  id: "stripedBackgroundPlugin",
  beforeDraw: (chart, args, options) => {
    if (!options || !options.color_ranges) {
      return;
    }

    const ctx = chart.ctx;
    const yAxis = chart.scales["y"];
    const chartArea = chart.chartArea;

    // Define your color ranges here
    const color_ranges = options.color_ranges;

    ctx.save();

    // Clip to the chart's chartArea
    ctx.beginPath();
    ctx.rect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
    ctx.clip();

    // Loop through each range to draw the stripes
    for (let i = 0; i < color_ranges.length; i++) {
      const range = color_ranges[i];

      // Calculate the pixel positions for the current range, ensuring they are within the chartArea
      let yMin = range.min !== undefined ? yAxis.getPixelForValue(range.min) : chartArea.bottom;
      let yMax = range.max !== undefined ? yAxis.getPixelForValue(range.max) : chartArea.top;

      // Ensure yMin is within the chartArea
      yMin = Math.max(chartArea.top, Math.min(yMin, chartArea.bottom));

      // Ensure yMax is within the chartArea
      yMax = Math.max(chartArea.top, Math.min(yMax, chartArea.bottom));

      // Draw the rectangle for the current stripe
      ctx.fillStyle = range.color;
      ctx.globalAlpha = 0.3; // Set the opacity level (0.3 for 30% opacity)
      ctx.fillRect(chartArea.left, yMin, chartArea.right - chartArea.left, yMax - yMin);

      // Reset the global alpha back to 1 (fully opaque)
      ctx.globalAlpha = 1;
    }

    ctx.restore();
  },
};

export { stripedBackgroundPlugin };
